import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import "./form-content.scss";

export function FormContent({ props }) {
  const showVideo =
    props.showVideo !== undefined && props.showVideo !== true
      ? props.showVideo
      : true;
  return (
    <>
      {!showVideo ? (
        <BrowserView>
          <div className="video-box">
            <iframe
              title="video"
              width="100%"
              height="100%"
              src={props.videoURL}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </BrowserView>
      ) : (
        <div className="text">
          <BrowserView>
            <p className="txt font-32 cl-white font-weight-700">
              <span className="title">{props.title}</span>
              <br />
            </p>
            <p className="sub font-24 cl-white font-weight-400">
              {props.subtitle}
            </p>
          </BrowserView>
        </div>
      )}
      {!showVideo ? (
        <MobileView>
          <div className="video-box">
            <iframe
              title="video"
              width="100%"
              height="100%"
              src={props.videoURL}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </MobileView>
      ) : (
        <>
          <MobileView>
            <p className="txt font-32 cl-white ">
              {props.title}
              <br />
              <b>{props.subtitle}</b>
            </p>
          </MobileView>

          <MobileView>
            <div className="img-container">
              <img src={props.imgMobileURL} alt={props.imgAlt} />
            </div>
          </MobileView>
          <BrowserView>
            <div className="img-container">
              <img
                className="image-form"
                src={props.imgURL}
                alt={props.imgAlt}
              />
            </div>
          </BrowserView>
        </>
      )}
    </>
  );
}
