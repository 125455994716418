const axios = require('axios');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = 'https://api-site-super-beneficios-bxcu5etpra-uc.a.run.app/';
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = 'https://superbeneficios.guilhermegonzales.com.br/';
} else {
  baseUrl = 'https://api-site-super-beneficios-bxcu5etpra-uc.a.run.app/'
}
var baseCheckout = 'https://run.checkout.googlecloud.superbeneficios.com/'
 
const api = axios.create({
  baseURL: baseUrl
})
const checkout = axios.create({
  baseURL: baseCheckout
})

const TOKEN = window.localStorage.getItem('token')

export { api, TOKEN,checkout }
