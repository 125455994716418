import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import "./Planos.scss";

import BgBanner from "../../images/planos/banner-background.png";

import BulletMais from "../../images/planos/super-pet/icon-plus3.svg";
import BulletMais1 from "../../images/planos/super-pet/icon-plus1.svg";
import BulletMais2 from "../../images/planos/super-pet/icon-plus2.svg";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Heart from "../../images/icon/coracao-garante.svg";

import ModalImage from "../../images/modal/image-modal.png";
import { BrowserView, MobileView } from "react-device-detect";
import { useSinglePrismicDocument, PrismicRichText } from "@prismicio/react";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const superPetPageGraphQuery = `{
        pagina_super_pet{
            logo_img
            img_aside_logo
            intro_text
            titulo
            icones
            aside_img
            image_footer
            image_footer_mobile
            text_footer
            btn_text_footer
            ad_newplans_link{
                ...on anuncio_de_novos_planos{
                new_plans
              }
            }
            btn_text_footer
            planos{
              plan_link{
              ...on planos_pet{
                  imagem_plano
                  itens
                  texto
                  price
                  btn_text
                  btn_link
              }
            }
          } 
        }
    }`.replace(/^ +| +$/gm, "");
  const [superPetPageContent] = useSinglePrismicDocument("pagina_super_pet", {
    graphQuery: superPetPageGraphQuery,
  });

  const propsBannerComponent = {
    imageFooter: superPetPageContent?.data.image_footer?.url
      ? superPetPageContent?.data.image_footer.url
      : "",
    imageFooterAlt: superPetPageContent?.data.image_footer?.alt
      ? superPetPageContent?.data.image_footer.alt
      : "",
    imageFooterMobile: superPetPageContent?.data.image_footer_mobile?.url
      ? superPetPageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: superPetPageContent?.data.image_footer_mobile?.alt
      ? superPetPageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: superPetPageContent?.data.text_footer[0]?.text
      ? superPetPageContent?.data.text_footer[0].text
      : "",
    btnText: superPetPageContent?.data.btn_text_footer[0]?.text
      ? superPetPageContent?.data.btn_text_footer[0].text
      : "",
    modalControl: setModalFtActive,
  };

  const mainContent = {
    logo: superPetPageContent?.data.logo_img?.url
      ? superPetPageContent?.data.logo_img.url
      : "",
    imgAsideLogo: superPetPageContent?.data.img_aside_logo?.url
      ? superPetPageContent?.data.img_aside_logo.url
      : "",
    imgAside: superPetPageContent?.data.aside_img?.url
      ? superPetPageContent?.data.aside_img.url
      : "",
    textIntro: superPetPageContent?.data.intro_text[0]?.text
      ? superPetPageContent.data.intro_text
      : "",
  };

  const plansFormatted = superPetPageContent?.data.planos?.map((plan) => {
    return {
      id: plan.plan_link.id,
      imageURL: plan.plan_link.data?.imagem_plano?.url
        ? plan.plan_link.data?.imagem_plano.url
        : "",
      imageAlt: plan.plan_link.data?.imagem_plano?.alt
        ? plan.plan_link.data?.imagem_plano.alt
        : "",
      items: plan.plan_link.data?.itens.map((x) => x.item[0].text),
      text: plan.plan_link.data?.texto ? plan.plan_link.data.texto[0].text : "",
      price: plan.plan_link.data?.price
        ? Number(
            plan.plan_link.data.price[0].text.replace(",", ".")
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        : "",
      btnText: plan.plan_link.data?.btn_text
        ? plan.plan_link.data.btn_text[0].text
        : "",
      btnLink: plan.plan_link.data?.btn_link
        ? plan.plan_link.data.btn_link[0].text
        : "",
    };
  });

  const iconsSection = {
    title: superPetPageContent?.data.titulo[0]?.text
      ? superPetPageContent.data.titulo
      : "",
    icons: superPetPageContent?.data.icones.map((x) => x.icone.url),
  };

  return (
    <div id="super-pet">
      <div id="banner">
        <img alt="" src={BgBanner} className="banner-bg animated fadeInDown" />

        <div className="content">
          <div className="row col-12 conteudos">
            <div className="col-12 d-flex row images">
              <div className="col-lg-6 col-md-12">
                <img
                  alt=""
                  className="img animated fadeInLeft"
                  src={mainContent.logo}
                />
              </div>
              <BrowserView>
                <div className="col-6">
                  <img
                    alt=""
                    className="img dog animated fadeInLeft"
                    src={mainContent.imgAsideLogo}
                  />
                </div>
              </BrowserView>
            </div>
            <PrismicRichText
              field={mainContent.textIntro}
              components={{
                paragraph: ({ children, node }) => (
                  <p key={node.text} className="cl-gray font-16">
                    {children}
                  </p>
                ),
              }}
            />
          </div>
        </div>
      </div>

      <BrowserView>
        <div id="vantavida">
          <div className="content">
            <div className="row">
              <div className="subtitulo col-12" align="">
                <PrismicRichText
                  field={iconsSection.title}
                  components={{
                    paragraph: ({ children, node }) => (
                      <p key={node.text} className="cl-ligth font-32 title">
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="row line">
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index < 5) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
            <div className="row line">
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index > 4 && index < 10) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
            <div className="row line2">
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index > 9 && index < 15) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
            <div className="row line2">
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index > 14) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
          </div>
        </div>
      </BrowserView>

      <div id="planos">
        <div className="content">
          <div className="row">
            {plansFormatted &&
              plansFormatted.map((plan, index) => {
                return (
                  <div key={plan.id} className="col-lg-4 col-md-12">
                    <ScrollAnimation animateIn="fadeIn" delay={100} animateOnce>
                      <div className="box bx1">
                        <div
                          className={
                            index === 0
                              ? "topo bg-p-blue1"
                              : index === 1
                              ? "topo bg-p-blue2"
                              : "topo bg-p-blue3"
                          }
                        >
                          <img alt={plan.imageAlt || ""} src={plan.imageURL} />
                        </div>
                        <div className="list">
                          {plan.items?.length > 0 &&
                            React.Children.toArray(
                              plan.items.map((item) => {
                                return (
                                  <span className="cl-gray font-18">
                                    <img
                                      alt=""
                                      src={
                                        index === 0
                                          ? BulletMais1
                                          : index === 1
                                          ? BulletMais2
                                          : BulletMais
                                      }
                                    />
                                    {item}
                                  </span>
                                );
                              })
                            )}
                        </div>
                        <div className="footer">
                          <p className="font-14 cl-gray f-wheight-700">
                            {plan.text}
                          </p>
                          <span className="cl-blue font-32 f-wheight-700 valor">
                            {plan.price}
                            <span className=" font-16 cl-gray f-wheight-500">
                              /mês
                            </span>
                          </span>
                          <Link
                            to={plan.btnLink}
                            className={
                              index === 0
                                ? "bt f-wheight-600 bg-p-blue1 cl-white font-18"
                                : index === 1
                                ? "bt f-wheight-600 bg-p-blue2 cl-white font-18"
                                : "bt f-wheight-600 bg-p-blue3 cl-white font-18"
                            }
                          >
                            {plan.btnText}{" "}
                            <img
                              alt=""
                              width="24"
                              height="24"
                              src={ArrowYellow}
                            />
                          </Link>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                );
              })}
            <BrowserView>
              <img
                alt=""
                className="familia-residencia"
                src={mainContent.imgAside}
              />
            </BrowserView>
          </div>
        </div>
      </div>

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img alt="" width="520" height="293" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img alt="" width="55" height="47" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
