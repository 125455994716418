import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import bgNaoGreenMobile from "../../images/nao-pedimos/bg-mobile1.svg";

import "./Sobre.scss";

import BgBanner from "../../images/sobre/banner-full.png";
import Map from "../../images/sobre/map.svg";

import IconNosso from "../../images/sobre/icon-nosso.svg";
import SBMini from "../../images/icon/mini-SB.svg";

import ArrowBlue from "../../images/icon/arrow-blue.svg";
import bgNaoGreen from "../../images/nao-pedimos/bg-green.svg";
import bgNaoGreen2 from "../../images/nao-pedimos/bg-green2.svg";
import bgNaoPontos from "../../images/nao-pedimos/bg-pontos.svg";
import bgNaoYellow from "../../images/nao-pedimos/bg-yellow.svg";
import bgNaoYellowM from "../../images/nao-pedimos/bg-yellowM.svg";
import bgImage from "../../images/sobre/image-analise.png";
import ModalImage from "../../images/modal/image-modal.png";
import Heart from "../../images/icon/coracao-garante.svg";
import ImageGarante from "../../images/sobre/image-garante.png";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const sobrePageGraphQuery = `{
    sobre_a_aaapv{
      texto
      titulo
      image_footer
      image_footer_mobile
      text_footer
      btn_text
    }
}`.replace(/^ +| +$/gm, "");
  const [sobrePageContent] = useSinglePrismicDocument("sobre_a_aaapv", {
    graphQuery: sobrePageGraphQuery,
  });

  const mainContent = {
    texto: sobrePageContent?.data.texto[0]?.text
      ? sobrePageContent.data.texto
      : "",
    titulo: sobrePageContent?.data.titulo[0]?.text
      ? sobrePageContent.data.titulo
      : "",
  };

  const propsBannerComponent = {
    imageFooter: sobrePageContent?.data.image_footer?.url
      ? sobrePageContent?.data.image_footer.url
      : "",
    imageFooterAlt: sobrePageContent?.data.image_footer?.alt
      ? sobrePageContent?.data.image_footer.alt
      : "",
    imageFooterMobile: sobrePageContent?.data.image_footer_mobile?.url
      ? sobrePageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: sobrePageContent?.data.image_footer_mobile?.alt
      ? sobrePageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: sobrePageContent?.data.text_footer[0]?.text
      ? sobrePageContent?.data.text_footer[0].text
      : "",
    btnText: sobrePageContent?.data.btn_text[0]?.text
      ? sobrePageContent?.data.btn_text[0].text
      : "",
    modalControl: setModalFtActive,
  };

  return (
    <div id="sobre">
      <div id="banner">
        <img alt="" src={BgBanner} className="animated fadeInDown" />
        <div className="content ">
          <div className="row conteudos main">
            <div className="col-lg-12 col-md-12 texts aaapv animated fadeIn">
              <PrismicRichText
                field={mainContent.titulo}
                components={{
                  paragraph: ({ children, node }) => (
                    <p key={node.text} className="cl-gray font-32 title">
                      {children}
                    </p>
                  ),
                }}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 texts-aaapv animated fadeIn">
            <PrismicRichText
              field={mainContent.texto}
              components={{
                paragraph: ({ children, node }) => (
                  <p
                    key={node.text}
                    className="texts-aaapv cl-black font-16 title"
                  >
                    {children}
                  </p>
                ),
                listItem: ({ children, node }) => (
                  <ul key={node.text}>
                    <li className="pl-4 mt-2 mb-2 cl-black font-16 title li-style">
                      {children}
                    </li>
                  </ul>
                ),
              }}
            />
          </div>
        </div>
      </div>

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img width="55" height="47" alt="" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
