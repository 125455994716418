import React, { useEffect, useState } from "react";
import { pwr } from "../../Api/pwr";

import "@brainhubeu/react-carousel/lib/style.css";

import "./Form.scss";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";
import axios from "axios";

import LogoAuto from "../../images/forms/logo-auto.svg";
import swal from "sweetalert";
import { useSinglePrismicDocument } from "@prismicio/react";
import { FormContent } from "../../Components/FormContent";

export default function Index(props) {
  const [passo1, setPasso1] = useState("");
  const [passo2, setPasso2] = useState("display-none");

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [estado, setEstado] = useState("");
  const [placa, setPlaca] = useState("");
  const [modelo, setModelo] = useState("");
  const [cidade, setCidade] = useState("");
  const [code, setCode] = useState("");

  const superAutoPageGraphQuery = `{
        pagina_super_auto{
            form_title
            form_subtitle
            form_img
            form_img_mobile
            form_video
            form_show_video
            
        }
    }`.replace(/^ +| +$/gm, "");
  const [superAutoPageContent] = useSinglePrismicDocument("pagina_super_auto", {
    graphQuery: superAutoPageGraphQuery,
  });

  const propsFormContent = {
    imgURL: superAutoPageContent?.data.form_img?.url
      ? superAutoPageContent?.data.form_img.url
      : "#",
    imgAlt: superAutoPageContent?.data.form_img?.alt
      ? superAutoPageContent?.data.form_img.alt
      : "",
    videoURL: superAutoPageContent?.data.form_video[0]?.text
      ? superAutoPageContent?.data.form_video[0].text
      : "",
    showVideo: superAutoPageContent?.data.form_show_video,
    imgMobileURL: superAutoPageContent?.data.form_img_mobile?.url
      ? superAutoPageContent?.data.form_img_mobile.url
      : "#",
    imgMobileAlt: superAutoPageContent?.data.form_img_mobile?.alt
      ? superAutoPageContent?.data.form_img_mobile.alt
      : "",
    title: superAutoPageContent?.data.form_title[0]?.text
      ? superAutoPageContent.data.form_title[0].text
      : "",
    subtitle: superAutoPageContent?.data.form_subtitle[0]?.text
      ? superAutoPageContent.data.form_subtitle[0].text
      : "",
  };

  useEffect(() => {
    window.loadScript("/jquery.min.js");
  }, []);

  async function insertPasso1() {
    let slsmnId = null;
    let slsmnNwId = null;
    const query = new URLSearchParams(props.location.search);
    let _id = query.get("id");
    if (_id && _id !== undefined && _id !== null) {
      slsmnNwId = _id;
    }

    let response = await pwr.post("/qttnStep1", {
      h: "sp beneficios507",
      slsmnId: slsmnId,
      slsmnNwId: slsmnNwId,
      name: nome,
      email: email,
      phone: telefone,
    });
    window.PowerCRM.init([211, "CODIGO-DO-CLIENTE"]);
    const origem = query.get("origin");
    if (origem) {
      await axios.get(
        "https://us-central1-wholesale-sms.cloudfunctions.net/quotation-powercrm?pg=/v2/set_origin_by_back&name=" +
          nome +
          "&back=" +
          response.data.back +
          "&origin_new_id=" +
          origem
      );
    }
    setCode(response.data.back);

    setPasso(2);
  }

  async function insertPasso2() {
    let response = await pwr.post("/qttnStep2", {
      code: code,
      h: "sp beneficios507",
      vhclTyp: tipo,
      vhclModel: modelo,
      pwrVhclPlts: placa,
      pwrVhclVrsn: anoModelo,
      pwrVhclMdl: modelo,
      cty: cidade,
      pwrStt: estado,
      vhclYear: anoModelo,
    });

    if (response.data) {
      swal("Enviado com sucesso!");
      setTimeout(function () {
        window.location.href = "/form-auto";
      }, 2000);
    }

    swal("Enviado com sucesso!");
  }

  async function setPasso(passo) {
    if (passo === 1) {
      setPasso1("");
      setPasso2("display-none");
      // setPasso3("display-none");
    } else if (passo === 2) {
      setPasso1("display-none");
      setPasso2("");
      // setPasso3("display-none");
    } else if (passo === 3) {
      setPasso1("display-none");
      setPasso2("display-none");
      // setPasso3("");
    }
  }

  return (
    <div id="credenciados">
      <div id="head"></div>
      <div id="formg">
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-12 left-form" align="center">
              <FormContent props={propsFormContent} />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="box-form row no-gutters form animated fadeIn">
                <div className="col-12 row no-gutters infos">
                  <img src={LogoAuto} alt="" />
                  <p className="font-16">
                    Temos vários planos para você ficar protegido e tranquilo.
                    <br />
                    <b className="cl-light-blue">
                      Não fazemos análise de Perfil.
                    </b>
                  </p>
                  <div className="box-bl bg-blue font-16 cl-yellow">
                    + Receba benefícios Super Saúde com seu Super Auto! Simule e
                    confira!
                  </div>
                </div>

                <div className={"col-12 row form-auto " + passo1}>
                  <div className="col-12">
                    <input
                      type="text"
                      id="pwrAssocNm"
                      maxLength="256"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      placeholder="Nome"
                    />
                  </div>

                  <div className="col-12">
                    <input
                      type="email"
                      id="pwrAssocEml"
                      maxLength="128"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="E-mail"
                    />
                  </div>

                  <div className="col-12" align="right">
                    <input
                      type="text"
                      id="pwrAssocPhn"
                      maxLength="31"
                      value={telefone}
                      onBlur={() => insertPasso1()}
                      onChange={(e) => setTelefone(e.target.value)}
                      placeholder="Telefone"
                    />
                  </div>

                  <div className="ipt-check col-12">
                    <button id="pwr_step_1_next" onClick={() => insertPasso1()}>
                      Próximo Passo
                      <img width="24" height="24" alt="" src={ArrowYellow} />
                    </button>
                  </div>
                </div>

                <div className={"col-12 row form-auto " + passo2}>
                  <div className="col-12">
                    <select
                      id="pwrVhclTp"
                      onChange={(e) => setTipo(e.target.value)}
                    ></select>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <input
                      type="text"
                      onChange={(e) => setPlaca(e.target.value)}
                      maxLength="7"
                      id="pwrVhclPlts"
                      placeholder="Placa"
                    />
                  </div>

                  <div className="col-lg-6 col-md-12" align="right">
                    <select
                      onChange={(e) => setPlaca(e.target.value)}
                      id="pwrVhclBrnd"
                    >
                      <option>Marca</option>
                    </select>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <select
                      onChange={(e) => setAnoModelo(e.target.value)}
                      id="pwrVhclVrsn"
                    >
                      <option>Ano Modelo</option>
                    </select>
                  </div>

                  <div className="col-lg-6 col-md-12" align="right">
                    <select
                      onChange={(e) => setModelo(e.target.value)}
                      id="pwrVhclMdl"
                    >
                      <option>Modelo</option>
                    </select>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <select
                      id="pwrStt"
                      onClick={(e) => setEstado(e.target.value)}
                    ></select>
                  </div>

                  <div className="col-lg-6 col-md-12" align="right">
                    <select
                      id="pwrCty"
                      onClick={(e) => setCidade(e.target.value)}
                      data-placeholder="Selecione a cidade"
                    >
                      <option>Cidade</option>
                    </select>
                  </div>

                  <div className="ipt-check col-12">
                    <input type="checkbox" name="" id="pwrWorkVhcl" />{" "}
                    <span>Taxi ou Uber?</span>
                  </div>

                  <div className="ipt-check col-12">
                    <button id="pwr_step_2" onClick={() => insertPasso2()}>
                      Receber cota&ccedil;&atilde;o{" "}
                      <img width="24" height="24" alt="" src={ArrowYellow} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
