import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import "./Planos.scss";

import { Carousel } from "react-responsive-carousel";

import { BrowserView, MobileView } from "react-device-detect";

import BgBanner from "../../images/planos/banner-background.png";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Heart from "../../images/icon/coracao-garante.svg";

import ModalImage from "../../images/modal/image-modal.png";
import LazyLoad from "react-lazyload";
import { useSinglePrismicDocument, PrismicRichText } from "@prismicio/react";
import { NewPlans } from "../../Components/NewPlans";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const superAutoPageGraphQuery = `{
        pagina_super_auto{
            plan_logo
            image_intro
            icons
            image_footer
            image_footer_mobile
            text_footer
            btn_text
            ad_newplans_link{
              ...on anuncio_de_novos_planos{
                new_plans
              }
            }
            plans{
              plan_link{
              ...on planos{
                  title
                  subtitle
                  short_text
                  observation
                  price
                  btn_text
                  btn_link
                  plan_image
              }
            }
          }      
        }
    }`.replace(/^ +| +$/gm, "");
  const [superAutoPageContent] = useSinglePrismicDocument("pagina_super_auto", {
    graphQuery: superAutoPageGraphQuery,
  });

  const newPlans = superAutoPageContent?.data.ad_newplans_link.data?.new_plans
    ? superAutoPageContent?.data.ad_newplans_link.data.new_plans
    : undefined;

  const mainContent = {
    logoImage: superAutoPageContent?.data.plan_logo?.url
      ? superAutoPageContent?.data.plan_logo.url
      : "",
    introImage: superAutoPageContent?.data.image_intro?.url
      ? superAutoPageContent?.data.image_intro.url
      : "",
  };

  const propsBannerComponent = {
    imageFooter: superAutoPageContent?.data.image_footer?.url
      ? superAutoPageContent?.data.image_footer.url
      : "",
    imageFooterAlt: superAutoPageContent?.data.image_footer?.alt
      ? superAutoPageContent?.data.image_footer.alt
      : "",
    imageFooterMobile: superAutoPageContent?.data.image_footer_mobile?.url
      ? superAutoPageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: superAutoPageContent?.data.image_footer_mobile?.alt
      ? superAutoPageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: superAutoPageContent?.data.text_footer[0]?.text
      ? superAutoPageContent?.data.text_footer[0].text
      : "",
    btnText: superAutoPageContent?.data.btn_text[0]?.text
      ? superAutoPageContent?.data.btn_text[0].text
      : "",
    modalControl: setModalFtActive,
  };

  //Conteúdo dos planos formatado em um objeto
  const plansFormatted = superAutoPageContent?.data.plans.map((plan) => {
    return {
      id: plan.plan_link.id,
      imageURL: plan?.plan_link.data?.plan_image?.url
        ? plan.plan_link.data?.plan_image.url
        : "/",
      imageAlt: plan.plan_link.data?.plan_image.alt
        ? plan.plan_link.data?.plan_image.alt
        : "",
      title: plan.plan_link.data?.title,
      subtitle: plan.plan_link.data?.subtitle
        ? plan.plan_link.data.subtitle[0].text
        : "",
      short_text: plan.plan_link.data?.short_text
        ? plan.plan_link.data.short_text[0].text
        : "",
      observation: plan.plan_link.data?.observation
        ? plan.plan_link.data.observation[0].text
        : "",
      price: plan.plan_link.data?.price
        ? Number(
            plan.plan_link.data.price[0].text.replace(",", ".")
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        : "",
      btnText: plan.plan_link.data?.btn_text
        ? plan.plan_link.data.btn_text[0].text
        : "",
      btnLink: plan.plan_link.data?.btn_link
        ? plan.plan_link.data.btn_link[0].text
        : "#",
    };
  });
  //Conteúdo dos ícones formatado em um objeto
  const iconsFormatted = superAutoPageContent?.data.icons?.map(
    (image, index) => {
      return {
        id: index,
        iconURL: image?.icon.url,
      };
    }
  );

  return (
    <div id="super-auto">
      <div id="banner">
        <img src={BgBanner} className="animated fadeInDown" alt="" />
        <div className="content">
          <div className="row conteudos">
            <div className="images col-12 ">
              <img
                className="img logo animated fadeInLeft"
                src={mainContent.logoImage}
                alt=""
              />
              <img
                className="img intro animated fadeInLeft"
                src={mainContent.introImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div id="produtos">
        <LazyLoad>
          <div className="content">
            <div className="row">
              <div className="col-12 row no-gutters">
                {plansFormatted &&
                  plansFormatted.map((plan) => {
                    return (
                      <LazyLoad
                        key={plan.id}
                        className="plan-card col-lg-4 col-md-12"
                        animateIn="fadeIn"
                      >
                        <ScrollAnimation animateIn="fadeIn">
                          <a
                            href={plan.btnLink}
                            className="col-lg-4 col-md-12 box"
                          >
                            <img
                              className="img-card"
                              alt={plan.imageAlt}
                              src={plan.imageURL}
                            ></img>

                            <PrismicRichText
                              field={plan.title}
                              components={{
                                paragraph: ({ children, node }) => (
                                  <p
                                    key={node.text}
                                    className="plan-title pdspc cl-gray font-28"
                                  >
                                    {children}
                                  </p>
                                ),
                              }}
                            />

                            {plan.short_text && (
                              <span
                                className="col-9 cl-gray text-center font-18 txt-pc font-p-mobal1"
                                align="center"
                              >
                                {plan.short_text}
                              </span>
                            )}
                            <span className="col-10 text-center cl-gray font-28 txt-pc font-p-mobal1">
                              {plan.subtitle}
                            </span>
                            <span className="cl-light-blue font-16 font-p-mobal1 tb-fipe">
                              {plan.observation}
                            </span>
                            <p className="cl-gray font-16 font-p-mobal1">
                              {plan.price && <span>Planos a partir de</span>}
                            </p>
                            <span className="cl-blue font-32 f-weight-700 font-p-mobal">
                              {plan.price ? (
                                <>
                                  <span>{plan.price}</span>
                                  <span className="cl-gray font-16 f-weight-400">
                                    /mensais
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                            <div className="button-card bg-light-blue cl-white bt font-18">
                              {plan.btnText}{" "}
                              <img
                                className="icon"
                                width="24"
                                height="24"
                                alt=""
                                src={ArrowYellow}
                              />
                            </div>
                          </a>
                        </ScrollAnimation>
                      </LazyLoad>
                    );
                  })}
              </div>
            </div>
          </div>
        </LazyLoad>
      </div>

      <BrowserView>
        <LazyLoad>
          <div id="vantauto">
            <div className="content">
              <div className="row">
                {iconsFormatted &&
                  iconsFormatted.map((icon) => {
                    return (
                      <div key={icon.id} className="col-2 image">
                        <img src={icon.iconURL} alt="" />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </LazyLoad>
      </BrowserView>

      <MobileView>
        <LazyLoad>
          <div id="vantauto">
            <Carousel
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              swipeable={true}
              emulateTouch={true}
              stopOnHover={true}
              infiniteLoop
              centerMode
              centerSlidePercentage={50}
              showThumbs={false}
            >
              {iconsFormatted &&
                iconsFormatted.map((icon) => {
                  return (
                    <div key={icon.id} className="col-2 image">
                      <img src={icon.iconURL} alt="" />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </LazyLoad>
      </MobileView>

      {newPlans !== undefined && <NewPlans arrayNewPlans={newPlans} />}

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <LazyLoad>
          <div id="modal-ini" className="bg-blue">
            <button
              onClick={(e) => setModalFtActive(false)}
              className="fechar"
            ></button>
            <img width="520" height="293" alt="" src={ModalImage} />
            <BrowserView>
              <div className="buttons">
                <Link
                  to="/form-auto"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Auto
                </Link>
                <Link
                  to="/form-saude"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Saúde
                </Link>
                <Link
                  to="/form-casa"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Residência
                </Link>
                <Link
                  to="/form-pet"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Pet
                </Link>
                <Link
                  to="/form-dental"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Odonto
                </Link>
              </div>
            </BrowserView>
            <MobileView>
              <div className="buttons">
                <a
                  href="/form-auto"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Auto
                </a>
                <a
                  href="/form-saude"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Saúde
                </a>
                <a
                  href="/form-casa"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Residência
                </a>
                <a
                  href="/form-pet"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Pet
                </a>
                <a
                  href="/form-dental"
                  onClick={(e) => setModalFtActive(false)}
                  className="font-16 cl-white border-white bt"
                >
                  Super Odonto
                </a>
              </div>
            </MobileView>
          </div>

          <div id="line-garante">
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <div className="box-2">
                    <img width="55" height="47" alt="" src={Heart} />
                    <span className="cl-blue font-28 f-weight-700">
                      Entenda como a Super
                      <br /> garante seus benefícios
                    </span>
                    <Link
                      to="/super-garante"
                      className="cl-blue border-blue bt"
                    >
                      Saiba mais
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
      </Modal>
    </div>
  );
}
