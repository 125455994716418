import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

import { BrowserView, MobileView } from "react-device-detect";

// import PhoneData from "./PhoneData";

import Modal from "../Modal/index";

import Logo from "../../images/logo/logo.svg";
import ArrowWhite from "../../images/icon/arrow-white.svg";
import SBMini from "../../images/icon/mini-SB.svg";

import Cookies from "../../images/icon/cookies.svg";
import Star from "../../images/sobre/star.svg";
import Whatsimage from "../../images/sobre/whats.svg";
import ModalCasa from "../../images/modal/casa.png";
import ModalOdonto from "../../images/modal/odonto.png";
import ModalPet from "../../images/modal/pet.png";

import ModalImage from "../../images/modal/image-modal.png";

import ModalCarros from "../../images/modal/carros.png";
import ModalSaude from "../../images/modal/saude.png";

import ImageContato from "../../images/icon/Mascote-chat.svg";

import Heart from "../../images/icon/coracao-garante.svg";

export default function Header({
  phonesData,
  modalFtActive,
  setModalFtActive,
}) {
  const [modalAssActive, setModalAssActive] = useState(false);
  const [modalCtActive, setModalCtActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [modalLGPD, setModalLGPD] = useState(true);
  const [activeLGPD, setActiveLGPD] = useState(null);

  function handleStateChange(state) {
    setMenuActive(state.isOpen);
  }
  function closeMenu() {
    setMenuActive(false);
  }

  useEffect(() => {
    if (!window.localStorage.getItem("LGPDS")) {
      setModalLGPD(true);
      if (modalLGPD === true) {
        setActiveLGPD("active");
      } else {
        setActiveLGPD(null);
      }
    }
  }, [modalLGPD]);
  return (
    <>
      <BrowserView>
        <header id="header" className="bg-purple remove_animated fadeIn">
          <link
            rel="preconnect"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          ></link>

          <div className="content">
            <div className="row no-gutters">
              <div className="col-2 logo">
                <Link to="/">
                  <img
                    width="203"
                    height="59"
                    src={Logo}
                    alt="Logo Super Beneficios"
                  />
                </Link>
              </div>

              <div className="col-5">
                <ul className="menu">
                  <li className="submenu2">
                    <Link to="/sobre" className="cl-white font-16">
                      Sobre
                    </Link>
                    <ul>
                      <li>
                        <Link to="/sobre" className="cl-gray font-16">
                          Sobre
                        </Link>
                      </li>
                      <li>
                        <Link to="/aaapv" className="cl-gray font-16">
                          Sobre AAAPV
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <Link to="/super-auto" className="cl-white  font-16">
                      Planos
                    </Link>
                    <ul>
                      <li>
                        <Link to="/super-auto" className="cl-gray font-16">
                          <img width="16" height="16" alt="" src={SBMini} />
                          Super<b>Auto</b>
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-vida" className="cl-gray font-16">
                          <img width="16" height="16" alt="" src={SBMini} />
                          Super<b>Saúde</b>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/super-residencia"
                          className="cl-gray font-16"
                        >
                          <img width="16" height="16" alt="" src={SBMini} />
                          Super<b>Residência</b>
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-odonto" className="cl-gray font-16">
                          <img width="16" height="16" alt="" src={SBMini} />
                          Super<b>Odonto</b>
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-pet" className="cl-gray font-16">
                          <img width="16" height="16" alt="" src={SBMini} />
                          Super<b>Pet</b>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/credenciados" className="cl-white  font-16">
                      Rede de credenciados
                    </Link>
                  </li>
                  <li>
                    <Link to="/desconto" className="cl-white  font-16">
                      Descontos
                    </Link>
                  </li>
                  <li>
                    <Link to="/garantia" className="cl-white  font-16">
                      Garantia
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-5 d-flex justify-content-end">
                <ul className="menu-right">
                  <li>
                    <Link to="/representante" className="cl-white n-bt font-16">
                      Seja um representante
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => setModalAssActive(true)}
                      className="cl-white bt border-white font-16 area-society"
                    >
                      Área do associado{" "}
                      <img
                        width="24"
                        height="24"
                        alt=""
                        className="icon"
                        src={ArrowWhite}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </BrowserView>

      <MobileView>
        <header id="header" className="bg-purple">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          ></link>
          <div className="content">
            <div className="row no-gutters ctr-logo">
              <div className="logo">
                <Link to="/">
                  <img width="203" height="59" alt="" src={Logo} />
                </Link>
              </div>

              <Menu
                isOpen={menuActive}
                onStateChange={(state) => handleStateChange(state)}
                className="menu-mobile"
                right
                width={"750px"}
              >
                <Link
                  to="/sobre"
                  onClick={(e) => closeMenu()}
                  className="cl-white font-16"
                >
                  Sobre
                </Link>
                <Link
                  to="/aaapv"
                  onClick={(e) => closeMenu()}
                  className="cl-white font-16"
                >
                  Sobre a AAAPV
                </Link>
                <Link
                  to="/super-auto"
                  onClick={(e) => closeMenu()}
                  className="cl-white font-16"
                >
                  {" "}
                  Super<b>Auto</b>
                </Link>
                <Link
                  to="/super-vida"
                  onClick={(e) => closeMenu()}
                  className="cl-white font-16"
                >
                  {" "}
                  Super<b>Saúde</b>
                </Link>
                <Link
                  to="/credenciados"
                  onClick={(e) => closeMenu()}
                  className="cl-white  font-16"
                >
                  Rede de credenciados
                </Link>
                <Link
                  to="/desconto"
                  onClick={(e) => closeMenu()}
                  className="cl-white  font-16"
                >
                  Descontos
                </Link>
                <Link
                  to="/garantia"
                  onClick={(e) => closeMenu()}
                  className="cl-white  font-16"
                >
                  Garantia
                </Link>
                <Link
                  to="/representante"
                  onClick={(e) => closeMenu()}
                  className="cl-white n-bt font-16"
                >
                  Seja um representante
                </Link>
                <Link
                  to="#"
                  onClick={(e) => setModalAssActive(true)}
                  className="cl-white bt border-white"
                >
                  Área do associado{" "}
                  <img
                    className="icon"
                    width="24"
                    height="24"
                    alt=""
                    src={ArrowWhite}
                  />
                </Link>
                <Link
                  to="#"
                  onClick={(e) => setModalFtActive(true)}
                  className="cl-white bt border-white "
                >
                  <img
                    className="img-left"
                    width="18"
                    height="17"
                    alt=""
                    src={Star}
                  />
                  Faça sua cotação online
                </Link>
                <a
                  href="https://api.whatsapp.com/send?phone=551140035542"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cl-white bt border-white "
                >
                  <img
                    width="24"
                    height="24"
                    alt=""
                    src={Whatsimage}
                    className="whta"
                  />{" "}
                  Fale conosco
                </a>
              </Menu>
            </div>
          </div>
        </header>
      </MobileView>

      <div id="contatos">
        <button
          onClick={(e) => setModalCtActive(true)}
          className="contato-image remove_animated zoomIn"
        >
          <img src={ImageContato} alt="Mascote" width="135" height="138" />
        </button>
      </div>

      <Modal width="wd-600" modalActive={modalAssActive}>
        <div className="row form" id="loginass">
          <div className="col-12">
            <button
              onClick={(e) => setModalAssActive(false)}
              className="fechar"
            ></button>

            <form
              action="https://taiga.hinova.com.br/sga/area/4.1/login.action.php"
              method="post"
            >
              <h2 className="uk-modal-title uk-heading-line uk-text-center">
                Área do Associado SUPER
              </h2>
              <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                  <span className="uk-form-icon" uk-icon="icon: user"></span>
                  <input
                    className="uk-input  uk-width-1-1"
                    type="text"
                    name="dfsCpf"
                    id="dfsCpf"
                    placeholder="Login"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                  <span className="uk-form-icon" uk-icon="icon: lock"></span>
                  <input
                    className="uk-input  uk-width-1-1"
                    type="password"
                    name="dfsSenha"
                    id="dfsSenha"
                    placeholder="Senha"
                  />
                </div>
              </div>
              <input
                type="hidden"
                name="dfsChave"
                id="dfsChave"
                value="33503858d3ccc8a4d3dcaa4987f92b2791cf03ba3be5328c9107fdd2adf8da361aa60731d8e96cc9dc05c2adf8e99d564a38e60575d7d6253b7ea6e66418a44a"
              />
              <div className="uk-margin">
                <input
                  type="submit"
                  name="pbEntrar"
                  value="Entrar"
                  className="uk-button uk-button-primary uk-width-1-1"
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <Link
                to="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </Link>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img width="55" height="47" alt="" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal width="wd-600" modalActive={modalCtActive}>
        <div id="modal-contatos" className="bg-white" align="left">
          <button
            onClick={(e) => setModalCtActive(false)}
            className="fechar"
          ></button>
          <span className="font-20 cl-light-blue f-weight-600">
            Entre em contato e vamos te ajudar :)
          </span>
          {phonesData.phonesFooter !== undefined && (
            <>
              {phonesData?.modal.phonesAuto?.length > 0 && (
                <div className="row box-md no-gutters lines-3">
                  <div className="col-3">
                    <img
                      width="110"
                      className="imagemCarrosModal"
                      height="108"
                      alt=""
                      src={ModalCarros}
                    />
                  </div>
                  <div className="col-9">
                    <span className="font-20 cl-light-blue f-weight-600">
                      Super Auto
                    </span>
                    {phonesData.modal &&
                      React.Children.toArray(
                        phonesData.modal.phonesAuto.map((data, index) => {
                          return (
                            <p className="font-18 cl-end-gray">
                              {data.title}{" "}
                              <a
                                className="cl-black font-18"
                                alt=""
                                href={`tel:${data.number}`}
                              >
                                {data.numberRaw}
                              </a>
                            </p>
                          );
                        })
                      )}
                  </div>
                </div>
              )}
            </>
          )}
          {phonesData.phonesFooter !== undefined && (
            <>
              {phonesData.modal.phonesHealth.length > 0 && (
                <div className="row box-md no-gutters">
                  <div className="col-3">
                    <img
                      width="108"
                      height="108"
                      alt=""
                      className="imagemSaudeModal"
                      src={ModalSaude}
                    />
                  </div>
                  <div className="col-9">
                    <span className="font-18 cl-light-blue f-weight-600">
                      Super Saúde
                    </span>
                    {phonesData.modal &&
                      React.Children.toArray(
                        phonesData.modal.phonesHealth.map((data, index) => {
                          return (
                            <p className="font-18 cl-end-gray">
                              {data.title}{" "}
                              <a
                                className="cl-black font-18"
                                alt=""
                                href={`tel:${data.number}`}
                              >
                                {data.numberRaw}
                              </a>
                            </p>
                          );
                        })
                      )}
                  </div>
                </div>
              )}
            </>
          )}
          {phonesData.phonesFooter !== undefined && (
            <>
              {phonesData.modal.phonesHome.length > 0 && (
                <div className="row box-md no-gutters">
                  <div className="col-3">
                    <img width="108" height="108" alt="" src={ModalCasa} />
                  </div>
                  <div className="col-9">
                    <span className="font-18 cl-light-blue f-weight-600">
                      Super Residência
                    </span>
                    {phonesData.modal &&
                      React.Children.toArray(
                        phonesData.modal.phonesHome.map((data, index) => {
                          return (
                            <p className="font-18 cl-end-gray">
                              {data.title}{" "}
                              <a
                                className="cl-black font-18"
                                alt=""
                                href={`tel:${data.number}`}
                              >
                                {data.numberRaw}
                              </a>
                            </p>
                          );
                        })
                      )}
                  </div>
                </div>
              )}
            </>
          )}

          {phonesData.phonesFooter !== undefined && (
            <>
              {phonesData.modal.phonesDental.length > 0 && (
                <div className="row box-md no-gutters">
                  <div className="col-3">
                    <img width="108" height="108" alt="" src={ModalOdonto} />
                  </div>
                  <div className="col-9">
                    <span className="font-18 cl-light-blue f-weight-600">
                      Super Odonto
                    </span>
                    {phonesData.modal &&
                      React.Children.toArray(
                        phonesData.modal.phonesDental.map((data, index) => {
                          return (
                            <p className="font-18 cl-end-gray">
                              {data.title}{" "}
                              <a
                                className="cl-black font-18"
                                alt=""
                                href={`tel:${data.number}`}
                              >
                                {data.numberRaw}
                              </a>
                            </p>
                          );
                        })
                      )}
                  </div>
                </div>
              )}
            </>
          )}
          {phonesData.phonesFooter !== undefined && (
            <>
              {phonesData.modal.phonesPet.length > 0 && (
                <div className="row box-md no-gutters">
                  <div className="col-3">
                    <img width="108" height="108" alt="" src={ModalPet} />
                  </div>
                  <div className="col-9">
                    <span className="font-18 cl-light-blue f-weight-600">
                      Super Pet
                    </span>
                    {phonesData.modal &&
                      React.Children.toArray(
                        phonesData.modal.phonesPet.map((data, index) => {
                          return (
                            <p className="font-18 cl-end-gray">
                              {data.title}{" "}
                              <a
                                className="cl-black font-18"
                                alt=""
                                href={`tel:${data.number}`}
                              >
                                {data.numberRaw}
                              </a>
                            </p>
                          );
                        })
                      )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
      <div id="modallgpd" className={`${activeLGPD}`}>
        <div>
          <div id="modal-ini">
            <img width="64" height="64" alt="" src={Cookies} />
            <p>
              Usamos cookies para personalizar e melhorar a sua experiência no
              site. Ao continuar navegando, você concorda com a nossa{" "}
              <Link to="/politica-de-privacidade">
                Política de Cookies e privacidade.
              </Link>
            </p>
            <button
              onClick={(e) =>
                window.localStorage.setItem("LGPDS", false) &
                setActiveLGPD(false)
              }
              className="fechar bt"
            >
              Continuar e fechar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
