import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import LogoFooter from "../../images/logo/logo-footer.svg";

import Logoaapv from "../../images/logo/aaapv.svg";
import Logofgrs from "../../images/logo/fgrs.svg";

import IconPlus from "../../images/icon/plusRep.svg";
import Whatsapp from "../../images/icon/whatsapp.svg";

import Instagram from "../../images/icon/instagram.svg";
import Facebook from "../../images/icon/facebook.svg";

import Star from "../../images/sobre/star.svg";
import Whatsimage from "../../images/sobre/whats.svg";
import SeloFooter from "../../images/garantia/selo-mobal.png";


import Modal from "../Modal/index";

import { BrowserView, MobileView } from "react-device-detect";

import LazyLoad from "react-lazyload";

export default function Footer({ phonesData, setModalFtActive }) {
  const [modalAssActive, setModalAssActive] = useState(false);

  const [modalLGPD, setModalLGPD] = useState(true);
  const [activeLGPD, setActiveLGPD] = useState(null);

  useEffect(() => {
    if (!window.localStorage.getItem("LGPDS")) {
      setModalLGPD(true);
      if (modalLGPD === true) {
        setActiveLGPD("active");
      } else {
        setActiveLGPD(null);
      }
    }
  }, [modalLGPD]);

  return (
    <LazyLoad>
      <footer
        id="footer"
        className={
          window.location.href === window.location.origin + "/desconto" || window.location.href === window.location.origin + "/garantia"
            ? "active"
            : " "
        }
      >
        <div id="footer-1">
          <div className="content">
            <div className="row no-gutters">
              <div className="col-lg-2 col-md-12">
                <img
                  className="logo-footer"
                  width="100%"
                  height="100%"
                  alt=""
                  src={LogoFooter}
                />
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="uls-menus">
                  <ul className="cl-white font-16 ">
                    <li>
                      <Link to="/sobre">Sobre</Link>
                    </li>
                    <li>
                      <Link to="/super-auto">Planos</Link>
                    </li>
                    <li>
                      <Link to="/politica-de-privacidade">
                        Política de Cookies e Privacidade
                      </Link>
                    </li>
                  </ul>

                  <ul className="cl-white font-16 ">
                    <li>
                      <Link to="/desconto">Descontos</Link>
                    </li>
                    <li>
                      <a
                        href="https://app.powercrm.com.br/login"
                        target="_black"
                      >
                        Área do <b>consultor</b>
                      </a>
                    </li>
                    <li>
                      <Link to="#" onClick={(e) => setModalAssActive(true)}>
                        Área do <b>associado</b>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5 col-md-12 bd-left imgs-footer">
                <img width="139" height="85" alt="" src={Logoaapv} />
                <a href="/garantia">
                  <img src={SeloFooter} width="200" alt="Selo FGRS" className="" />
                </a>
                <img
                  className="d-none"
                  width="226"
                  height="82"
                  alt=""
                  src={Logofgrs}
                />
              </div>

              <div className="col-lg-12 col-md-12 row no-gutters ft-line2">
                <div className="col-lg-5 col-md-12 ft-buttons">
                  <Link
                    to="/representante"
                    className="bt border-blue cl-blue f-weight-700 font-14"
                  >
                    <img width="24" height="24" alt="" src={IconPlus} />
                    Seja um representante
                  </Link>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phonesData.whatsNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="bt border-blue cl-blue f-weight-700 font-14"
                  >
                    <img width="24" height="24" alt="" src={Whatsapp} />
                    Associe-se pelo WhatsApp
                  </a>
                </div>
                <div className="col-lg-1 col-md-12 ft-redes">
                  <a
                    href="https://www.facebook.com/superbeneficios"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img width="100%" height="100%" alt="" src={Facebook} />
                  </a>
                  <a
                    href="https://www.instagram.com/superbeneficios/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img width="100%" height="100%" alt="" src={Instagram} />
                  </a>
                </div>
                <div className="col-lg-6 col-md-12 ft-contatos" align="left">
                  <BrowserView>
                    {phonesData.phonesFooter !== undefined &&
                      React.Children.toArray(
                        phonesData.phonesFooter.map((data, index) => {
                          return (
                            <>
                              <p className="cl-black f-weight-700">
                                {data.title}{" "}
                                {React.Children.toArray(
                                  data.numberRaw.map((tel, index) => {
                                    return (
                                      <span>
                                        {index >= 1 && "|"}
                                        <a
                                          className="cl-blue f-weight-400"
                                          href={
                                            typeof data.number !== "string"
                                              ? `tel:${data.number[index]}`
                                              : `tel:${data.number}`
                                          }
                                          alt=""
                                          rel="noopener noreferrer"
                                        >
                                          {tel}
                                        </a>
                                      </span>
                                    );
                                  })
                                )}
                              </p>
                            </>
                          );
                        })
                      )}
                  </BrowserView>

                  <MobileView>
                    {phonesData.phonesFooter !== undefined &&
                      React.Children.toArray(
                        phonesData.phonesFooter.map((data, index) => {
                          return (
                            <>
                              <p className="cl-black f-weight-700">
                                {data.title}{" "}
                                {React.Children.toArray(
                                  data.numberRaw.map((tel, index) => {
                                    return (
                                      <span>
                                        <a
                                          className="cl-blue f-weight-400"
                                          href={
                                            typeof data.number !== "string"
                                              ? `tel:${data.number[index]}`
                                              : `tel:${data.number}`
                                          }
                                          alt=""
                                          rel="noopener noreferrer"
                                        >
                                          {tel}
                                        </a>
                                      </span>
                                    );
                                  })
                                )}
                              </p>
                            </>
                          );
                        })
                      )}
                  </MobileView>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-2">
          <div className="content">
            <div className="row no-gutters">
              <div className="col-md-12 col-lg-6" align="left">
                <p className="font-14 cl-black">
                  © <b>2020 Super Benefícios.</b> Todos os direitos reservados
                </p>
              </div>
              <div className="col-md-12 col-lg-6" align="right">
                <p className="prod font-14 cl-black">
                  Produzido por{" "}
                  <a
                    href="https://tropa.digital"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="f-wheight-600 cl-black"
                  >
                    {" "}
                    Tropa Digital{" "}
                  </a>{" "}
                  e{" "}
                  <a
                    href="https://somocollab.com.br"
                    className="f-wheight-600 cl-black"
                    to=""
                  >
                    SOMO Collab
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="tudo" className="remove_animated fadeInUp">
          <div className="content">
            <BrowserView>
              <div className="row">
                <div className="col-7 texts">
                  <p className="cl-black">
                    Tudo isso por preços que cabem no seu bolso |{" "}
                    <span className="cl-light-blue">
                      <b>Atendemos 24 horas em todo Brasil</b>
                    </span>
                  </p>
                </div>
                <div className="col-lg-5 col-md-12 buttons">
                  <button
                    onClick={(e) => setModalFtActive(true)}
                    className="cl-white bg-blue bt cotation-hover btn-cotacao"
                  >
                    <img width="18" height="17" alt="" src={Star} />
                    Faça sua cotação online
                  </button>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phonesData.whatsNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="cl-blue bt border-blue whatsapp-hover whats"
                  >
                    <img
                      width="24"
                      height="24"
                      alt=""
                      rel="noopener"
                      src={Whatsimage}
                    />{" "}
                    Fale conosco
                  </a>
                </div>
              </div>
            </BrowserView>
            <MobileView>
              <div className="row">
                <div className="col-12 buttons">
                  <button
                    onClick={(e) => setModalFtActive(true)}
                    className="cl-white bg-blue bt btn-cotacao"
                  >
                    <img width="18" height="17" alt="" src={Star} />
                    Faça sua cotação online
                  </button>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phonesData.whatsNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="cl-blue bt border-blue whats"
                  >
                    <img
                      width="24"
                      height="24"
                      alt=""
                      rel="noopener"
                      src={Whatsimage}
                    />{" "}
                    Fale conosco
                  </a>
                </div>
              </div>
            </MobileView>
          </div>
        </div>

        <Modal width="wd-600" modalActive={modalAssActive}>
          <div className="row form" id="loginass">
            <div className="col-12">
              <button
                onClick={(e) => setModalAssActive(false)}
                className="fechar"
              ></button>

              <form
                action="https://orion.hinova.com.br/sga/area/4.1/login.action.php"
                method="post"
              >
                <h2 className="uk-modal-title uk-heading-line uk-text-center">
                  Área do Associado SUPER
                </h2>
                <div className="uk-margin">
                  <div className="uk-inline uk-width-1-1">
                    <span className="uk-form-icon" uk-icon="icon: user"></span>
                    <input
                      className="uk-input  uk-width-1-1"
                      type="text"
                      name="dfsCpf"
                      id="dfsCpf2"
                      placeholder="Login"
                    />
                  </div>
                </div>
                <div className="uk-margin">
                  <div className="uk-inline uk-width-1-1">
                    <span className="uk-form-icon" uk-icon="icon: lock"></span>
                    <input
                      className="uk-input  uk-width-1-1"
                      type="password"
                      name="dfsSenha"
                      id="dfsSenha2"
                      placeholder="Senha"
                    />
                  </div>
                </div>
                <input
                  type="hidden"
                  name="dfsChave"
                  id="dfsChave2"
                  value="cb2404021e853208529154e71c9cbba62087f75c2a4f6012aac868ea0719267133f49d21748b3de47b3e691ee1420a5e98e820debc949c59c039f7c29cbdc5b7"
                />
                <div className="uk-margin">
                  <input
                    type="submit"
                    name="pbEntrar"
                    value="Entrar"
                    className="uk-button uk-button-primary uk-width-1-1"
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </footer>
    </LazyLoad >
  );
}
