import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import "./Planos.scss";

import BulletMais from "../../images/planos/super-vida/icon-plus3.svg";
import BulletMais1 from "../../images/planos/super-vida/icon-plus1.svg";
import BulletMais2 from "../../images/planos/super-vida/icon-plus2.svg";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Heart from "../../images/icon/coracao-garante.svg";

import ModalImage from "../../images/modal/image-modal.png";
import { BrowserView, MobileView } from "react-device-detect";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const superResidenciaPageGraphQuery = `{
      pagina_super_residencia{
        logo_img
        img_aside_logo
        texto
        titulo
        footer_img
        planos{
          plano{
            ...on planos_residencia{
              imagem_plano
              itens
              texto
              price
              btn_text
              btn_link
            } 
          }
        } 
      }
  }`.replace(/^ +| +$/gm, "");
  const [superResidenciaPageContent] = useSinglePrismicDocument(
    "pagina_super_residencia",
    {
      graphQuery: superResidenciaPageGraphQuery,
    }
  );

  const mainContent = {
    logo: superResidenciaPageContent?.data.logo_img?.url
      ? superResidenciaPageContent?.data.logo_img.url
      : "",
    logoAlt: superResidenciaPageContent?.data.logo_img?.alt
      ? superResidenciaPageContent?.data.logo_img.alt
      : "",
    imgAsideLogo: superResidenciaPageContent?.data.img_aside_logo?.url
      ? superResidenciaPageContent?.data.img_aside_logo.url
      : "",
    imgFooter: superResidenciaPageContent?.data.footer_img?.url
      ? superResidenciaPageContent?.data.footer_img.url
      : "",
    texto: superResidenciaPageContent?.data.texto[0]?.text
      ? superResidenciaPageContent.data.texto
      : "",
    titulo: superResidenciaPageContent?.data.titulo[0]?.text
      ? superResidenciaPageContent.data.titulo[0].text
      : "",
  };

  const plansFormatted = superResidenciaPageContent?.data.planos?.map(
    (plan) => {
      return {
        id: plan.plano.id,
        imageURL: plan.plano.data?.imagem_plano?.url
          ? plan.plano.data?.imagem_plano.url
          : "",
        imageAlt: plan.plano.data?.imagem_plano?.alt
          ? plan.plano.data?.imagem_plano.alt
          : "",
        items: plan.plano.data?.itens.map((x) => x.item[0].text),
        text: plan.plano.data?.texto ? plan.plano.data.texto[0].text : "",
        price: plan.plano.data?.price
          ? Number(plan.plano.data.price).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "",
        btnText: plan.plano.data?.btn_text
          ? plan.plano.data.btn_text[0].text
          : "",
        btnLink: plan.plano.data?.btn_link[0]?.text
          ? plan.plano.data.btn_link[0]?.text
          : "",
      };
    }
  );

  return (
    <div id="super-residencia">
      <div id="banner">
        <div className="content">
          <div className="row conteudos">
            <div className="col-12 col-lg-7">
              <img
                alt={mainContent.logoAlt}
                className="img logo animated fadeInLeft"
                src={mainContent.logo}
                width="489"
              />
              <div className="row cl-blue f-weight-700 font-24 titulo">
                <b>{mainContent.titulo}</b>
              </div>
              <div className="row font-24">
                <PrismicRichText
                  field={mainContent.texto}
                  components={{
                    paragraph: ({ children, node }) => (
                      <p key={node.text} className="cl-gray font-24">
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>
            </div>
            <BrowserView className="col-5">
              <div>
                <img
                  alt=""
                  className="img-aside animated fadeInLeft"
                  src={mainContent.imgAsideLogo}
                />
              </div>
            </BrowserView>
          </div>
        </div>
      </div>

      <div id="planos">
        <div className="content">
          <div className="row">
            {plansFormatted &&
              plansFormatted.map((plan, index) => {
                return (
                  <div key={plan.id} className="col-lg-4 col-md-12">
                    <ScrollAnimation animateIn="fadeIn" delay={100} animateOnce>
                      <div className="box bx1">
                        <div
                          className={
                            index === 0
                              ? "topo bg-p-blue1"
                              : index === 1
                              ? "topo bg-p-blue2"
                              : "topo bg-p-blue3"
                          }
                        >
                          <img alt={plan.imageAlt || ""} src={plan.imageURL} />
                        </div>
                        <div className="list">
                          {plan.items?.length > 0 &&
                            React.Children.toArray(
                              plan.items.map((item) => {
                                return (
                                  <span className="cl-gray font-18">
                                    <img
                                      alt=""
                                      src={
                                        index === 0
                                          ? BulletMais1
                                          : index === 1
                                          ? BulletMais2
                                          : BulletMais
                                      }
                                    />
                                    {item}
                                  </span>
                                );
                              })
                            )}
                        </div>
                        <div className="footer">
                          <p className="font-14 cl-gray f-wheight-700">
                            {plan.text}
                          </p>
                          <span className="cl-blue font-32 f-wheight-700 valor">
                            {plan.price}
                            <span className=" font-16 cl-gray f-wheight-500">
                              /mês
                            </span>
                          </span>
                          <a
                            href={plan.btnLink}
                            className={
                              index === 0
                                ? "bt f-wheight-600 bg-p-blue1 cl-white font-18"
                                : index === 1
                                ? "bt f-wheight-600 bg-p-blue2 cl-white font-18"
                                : "bt f-wheight-600 bg-p-blue3 cl-white font-18"
                            }
                          >
                            {plan.btnText}{" "}
                            <img
                              alt=""
                              width="24"
                              height="24"
                              src={ArrowYellow}
                            />
                          </a>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                );
              })}
            <BrowserView>
              <img
                alt=""
                className="familia-residencia"
                src={mainContent.imgFooter}
              />
            </BrowserView>
          </div>
        </div>
      </div>

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div id="line-garante" className="d-none">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img alt="" width="55" height="47" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
