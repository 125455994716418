import React from "react";
import { Link } from "react-router-dom";

import "./Politicas.scss";

export default function Index() {
  return (
    <>
      <div id="politicas">
        <div id="menu">
          <div className="content">
            <div className="line-menu">
              <Link
                to="/politica-de-privacidade"
                className="bt border-white cl-white font-16"
              >
                Política de privacidade e Cookies
              </Link>
              <Link
                to="/como-usamos-seus-dados"
                className="bt border-white cl-white font-16"
              >
                Como usamos seus dados?
              </Link>
            </div>
          </div>
        </div>
        <div className="conteudo">
          <div className="content">
            <br />
            <br />
            <span className="cl-blue font-32 font-weight-400 titles">Como</span>
            <h2 className="cl-blue font-32 font-weight-800 titles">
              usamos seus dados?
            </h2>
            <br />
            <p className="font-18 cl-gray font-weight-500">
              A segurança de suas informações e privacidade são muito
              importantes para o Super Benefícios. Saiba como utilizamos seus
              dados.
            </p>
            <br />
            <span className="font-18 cl-light-blue font-weight-800">Nome</span>
            <p className="font-18 cl-gray font-weight-500">
              Seu nome é utilizado para identificação e validação do cadastro em
              nossa plataforma.
            </p>
            <br />
            <span className="font-18 cl-light-blue font-weight-800">
              Telefone
            </span>
            <p className="font-18 cl-gray font-weight-500">
              Seu telefone é utilizado para que possamos enviar comunicações
              sobre os produtos e serviços disponibilizados.
            </p>
            <br />
            <span className="font-18 cl-light-blue font-weight-800">
              E-mail
            </span>
            <p className="font-18 cl-gray font-weight-500">
              Utilizamos seu e-mail para o envio de comunicações relativas ao
              andamento da sua cotação e também para ofertas de produtos.
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
