import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import "./Sobre.scss";

import BgBanner from "../../images/sobre/banner-full.png";
import Map from "../../images/sobre/map.svg";

import ModalImage from "../../images/modal/image-modal.png";

import Heart from "../../images/icon/coracao-garante.svg";
import ImageGarante from "../../images/sobre/image-garante.png";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const sobrePageGraphQuery = `{
        pagina_sobre{
          texto_sobre
          titulo
          image
          image_footer
          image_footer_mobile
          text_footer
          btn_text
        }
    }`.replace(/^ +| +$/gm, "");
  const [sobrePageContent] = useSinglePrismicDocument("pagina_sobre", {
    graphQuery: sobrePageGraphQuery,
  });

  const propsBannerComponent = {
    imageFooter: sobrePageContent?.data.image_footer?.url
      ? sobrePageContent?.data.image_footer.url
      : "",
    imageFooterAlt: sobrePageContent?.data.image_footer?.alt
      ? sobrePageContent?.data.image_footer.alt
      : "",
    imageFooterMobile: sobrePageContent?.data.image_footer_mobile?.url
      ? sobrePageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: sobrePageContent?.data.image_footer_mobile?.alt
      ? sobrePageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: sobrePageContent?.data.text_footer[0]?.text
      ? sobrePageContent?.data.text_footer[0].text
      : "",
    btnText: sobrePageContent?.data.btn_text[0]?.text
      ? sobrePageContent?.data.btn_text[0].text
      : "",
    modalControl: setModalFtActive,
  };

  const mainContent = {
    image: sobrePageContent?.data.image?.url
      ? sobrePageContent?.data.image.url
      : "",
    texto: sobrePageContent?.data.texto_sobre[0]?.text
      ? sobrePageContent.data.texto_sobre
      : "",
    titulo: sobrePageContent?.data.titulo[0]?.text
      ? sobrePageContent.data.titulo
      : "",
  };

  return (
    <div id="sobre">
      <div id="banner">
        <img alt="" src={BgBanner} className="animated fadeInDown" />
        <div className="content">
          <div className="row conteudos">
            <MobileView>
              <div className="col-lg-5 col-md-12">
                <img className="imgmap animated fadeIn" src={Map} />
              </div>
            </MobileView>
            <div className="col-lg-7 col-md-12 texts animated fadeIn">
              <PrismicRichText
                field={mainContent.titulo}
                components={{
                  paragraph: ({ children, node }) => (
                    <p key={node.text} className="cl-gray font-32 title">
                      {children}
                    </p>
                  ),
                }}
              />
              <PrismicRichText
                field={mainContent.texto}
                components={{
                  paragraph: ({ children, node }) => (
                    <p key={node.text} className="font-16 cl-black text">
                      {children}
                    </p>
                  ),
                }}
              />
            </div>
            <BrowserView>
              <div className="col-5">
                <img
                  alt=""
                  className="imgmap animated fadeInRight"
                  src={mainContent.image}
                />
              </div>
            </BrowserView>
          </div>
        </div>
      </div>

      <div className="d-none" id="supergarantesobre">
        <div className="content">
          <div className="row d-none">
            <div className="col-12 title">
              <img alt="" src={Heart} />
              <p className="font-28 cl-blue f-weight-700">
                Entenda como a Super garante seus benefícios
              </p>
            </div>
          </div>
          <div className="row mg-top-sobre">
            <div className="col-2 image mb-none">
              <img alt="" src={ImageGarante} />
            </div>
            <div className="col-md-12 col-lg-10 text">
              <p className="cl-gray font-16  ">
                <b>
                  A SUPER BENEFÍCIOS faz parte da AAAPV, e isso traz segurança
                  pra você e credibilidade e solidez para que possamos oferecer
                  os mais diversos benefícios e trazer mais qualidade de vida
                  aos associados.
                </b>
                <br />
                <br />

                <span className="d-none">
                  <b>O Fundo Garantidor contra Riscos Sistêmicos</b> (FGRS) é
                  uma iniciativa da AAAPV (Agência de Autorregulamentação das
                  Entidades de Autogestão de Planos de Proteção Contra Riscos
                  Patrimoniais) para consolidar ainda mais a atuação das mútuas
                  no segmento de proteção patrimonial em nosso país. Com a
                  missão de amparar o associado/cooperado no caso de insolvência
                  de alguma mútua filiada.
                  <br />
                  <br />A Super Benefícios, como filiada é acompanhada e
                  auditada com frequência pela AAAPV. Seguimos regras de
                  compliance e de gestão estabelecidas por ela e pelo FGRS e
                  assim mantemos nosso selo. Com monitoramento frequente e
                  participamos de orientações para detectar riscos de
                  insolvência. Todavia, caso ocorra, o FGRS cobrirá, dentro dos
                  limites estabelecidos no regulamento do fundo, os eventos que
                  estiverem abertos, sejam eles de perda parcial, total ou
                  roubo/furto.
                </span>
              </p>
            </div>

            <div className="col-12 d-none" align="center">
              <a
                href="https://fgrs.com.br"
                target="_blank"
                className="cl-blue bt border-blue font-16"
              >
                <b>Acesse o site da AAAPV</b>
              </a>
            </div>
          </div>
        </div>
      </div>
      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img width="55" height="47" alt="" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
