import React, { useState } from "react";

import { Carousel } from "react-responsive-carousel";

import InputMask from "react-input-mask";

import "./Representante.scss";

import BgBanner from "../../images/representantes/banner.svg";
import ImageBanner2 from "../../images/representantes/image-banner-2.svg";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import swal from "sweetalert";
import { api } from "../../Api/app";

import ArrowDown from "../../images/representantes/arrow-down.svg";
import { BrowserView, MobileView } from "react-device-detect";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";

export default function Index() {
  const [camposFormulario, setCamposFormularios] = useState({
    nome: "",
    telefone: "",
    email: "",
    mensagem: "",
  });

  async function handleSend(event) {
    event.preventDefault();

    var data = camposFormulario;
    if (data.email.indexOf("@") < 0) {
      data.email = "email@naopreenchido.com";
    }

    // setstepLoad(true);
    swal(
      "A Super Benefícios agradece o interesse. Estamos te direcionando para nosso Grupo no WhatsApp"
    );
    setTimeout(function () {
      window.location.href = "https://chat.whatsapp.com/KQzBxEkWEYt7Ha6labYEd3";
    }, 2000);
    var response = await api.post("/form-consultor", data);

    if (response.data.status === "error") {
      alert(response.data.message);
    } else {
      swal(
        "A Super Benefícios agradece o interesse. Estamos te direcionando para nosso Grupo no WhatsApp"
      );
      setTimeout(function () {
        window.location.href =
          "https://chat.whatsapp.com/KQzBxEkWEYt7Ha6labYEd3";
      }, 2000);
    }

    setTimeout(function () {
      // setstepLoad(false);
    });
  }

  function changeInputMult(e) {
    var meusdados = camposFormulario;
    meusdados[e.target.name] = e.target.value;
    setCamposFormularios({ ...meusdados });
  }

  const representPageGraphQuery = `{
        pagina_seja_representante{
            video_link
            show_video
            show_center_video
            posts{
               post{
                ...on blog_posts{
                    imagem_do_post
                    titulo
                    resumo
                    post_link
                }
               } 
            }
            intro_title
            intro_text
            intro_subtitle
            intro_img
            icones
            form_title
            form_text
            center_side_text
            center_side_title
            btn_text
            btn_link
            aside_center_video_link
            aside_center_img
        }
    }`.replace(/^ +| +$/gm, "");
  const [representPageContent] = useSinglePrismicDocument(
    "pagina_seja_representante",
    {
      graphQuery: representPageGraphQuery,
    }
  );

  const mainContent = {
    showIntroVideoBoolean: representPageContent?.data.show_video,

    introVideoURL: representPageContent?.data.video_link[0]?.text
      ? representPageContent?.data.video_link[0].text
      : "",
    introImgURL:
      representPageContent?.data.intro_img.url !== null
        ? representPageContent?.data.intro_img.url
        : "",
    altIntroImgURL:
      representPageContent?.data.intro_img.alt !== null
        ? representPageContent?.data.intro_img.alt
        : "",
    titleIntro: representPageContent?.data.intro_title[0]?.text
      ? representPageContent?.data.intro_title[0].text
      : "",
    textIntro: representPageContent?.data.intro_text[0]?.text
      ? representPageContent?.data.intro_text[0].text
      : "",
    subtitleIntro: representPageContent?.data.intro_subtitle[0]?.text
      ? representPageContent?.data.intro_subtitle[0].text
      : "",
    formTitle: representPageContent?.data.form_title[0]?.text
      ? representPageContent?.data.form_title
      : "",
    formText: representPageContent?.data.form_text[0]?.text
      ? representPageContent?.data.form_text[0].text
      : "",
  };

  const centerContent = {
    showCenterVideoBoolean: representPageContent?.data.show_center_video,
    centerVideoURL: representPageContent?.data.aside_center_video_link[0]?.text
      ? representPageContent?.data.aside_center_video_link[0].text
      : "",
    centerImgURL:
      representPageContent?.data.aside_center_img.url !== null
        ? representPageContent?.data.aside_center_img.url
        : "",
    altCenterImgURL:
      representPageContent?.data.aside_center_img.alt !== null
        ? representPageContent?.data.aside_center_img.alt
        : "",
    btnText: representPageContent?.data.btn_text[0]?.text
      ? representPageContent?.data.btn_text[0].text
      : "",
    btnLink: representPageContent?.data.btn_link[0]?.text
      ? representPageContent?.data.btn_link[0].text
      : "#contatoa",
    centerTitle: representPageContent?.data.center_side_title[0]?.text
      ? representPageContent?.data.center_side_title[0].text
      : "",
    centerText: representPageContent?.data.center_side_text[0]?.text
      ? representPageContent?.data.center_side_text
      : "",
  };

  const blogPosts = representPageContent?.data.posts.map((x) => {
    return {
      id: x.post.id,
      title: x.post.data?.titulo[0]?.text ? x.post.data.titulo[0].text : "",
      excerpt: x.post.data?.resumo[0]?.text ? x.post.data.resumo : "",
      imgURL:
        x.post.data?.imagem_do_post.url !== null
          ? x.post.data?.imagem_do_post.url
          : "",
      imgAlt:
        x.post.data?.imagem_do_post.alt !== null
          ? x.post.data?.imagem_do_post.alt
          : "",
      postLink: x.post.data?.post_link[0]?.text
        ? x.post.data.post_link[0]?.text
        : "#",
    };
  });

  const icons = representPageContent?.data.icones.map((x) => {
    return {
      iconURL: x.icone?.url !== null ? x.icone?.url : "",
      iconAlt: x.icone?.alt !== null ? x.icone?.alt : "",
    };
  });

  return (
    <div id="Representante">
      <div id="banner">
        <img alt="" src={BgBanner} className="animated fadeInDown" />
        <div className="content">
          <div className="row conteudos">
            <div className="col-lg-7 col-md-12 image animated fadeIn">
              {!mainContent.showIntroVideoBoolean &&
              mainContent.introVideoURL !== "" ? (
                <div className="video-box intro">
                  <iframe
                    title="video"
                    width="100%"
                    height="100%"
                    src={mainContent.introVideoURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <div>
                  <img
                    className="image-intro"
                    src={mainContent.introImgURL}
                    alt={
                      mainContent.altIntroImgURL !== undefined
                        ? mainContent.altIntroImgURL
                        : ""
                    }
                  />
                </div>
              )}
            </div>

            <div className="col-lg-5 col-md-12 ">
              <div className="texts">
                <p className="cl-light-blue font-48 f-weight-700">
                  {mainContent.titleIntro}
                </p>
                <p className="cl-gray font-24 f-weight-700">
                  {mainContent.subtitleIntro}
                </p>
                <p className="cl-gray font-16">{mainContent.textIntro}</p>
                <img alt="" src={ImageBanner2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contato">
        <div className="content">
          <div className="row">
            <div className="box col-12 bg-light-blue row no-gutters">
              <div className="col-lg-4 ml-lg-5 col-md-12 texts">
                <PrismicRichText
                  field={mainContent.formTitle}
                  components={{
                    paragraph: ({ children, node }) => (
                      <p
                        key={node.text}
                        className="paragraph font-32 cl-white f-weight-700"
                      >
                        {children}
                      </p>
                    ),
                  }}
                />

                <BrowserView>
                  <p className="font-16 cl-white">{mainContent.formText}</p>
                </BrowserView>
                <MobileView>
                  <p className="font-16 cl-white">{mainContent.formText}</p>
                </MobileView>
              </div>
              <div className="col-lg-7 col-md-12">
                <form method="post" className="row" onSubmit={handleSend}>
                  <label className="col-12">
                    <input
                      required
                      value={camposFormulario.nome}
                      onChange={(e) => changeInputMult(e)}
                      name="nome"
                      placeholder="Nome Completo"
                    />
                  </label>
                  <label className="col-6">
                    <input
                      required
                      value={camposFormulario.email}
                      onChange={(e) => changeInputMult(e)}
                      name="email"
                      placeholder="Email"
                    />
                  </label>
                  <label className="col-6">
                    <InputMask
                      mask="(99) 99999-9999"
                      required
                      type="text"
                      value={camposFormulario.telefone}
                      onChange={(e) => changeInputMult(e)}
                      name="telefone"
                      placeholder="Telefone"
                    />
                  </label>
                  <label className="col-12">
                    <textarea
                      required
                      value={camposFormulario.mensagem}
                      onChange={(e) => changeInputMult(e)}
                      name="mensagem"
                      placeholder="Mensagem"
                    ></textarea>
                  </label>

                  <label className="col-12" align="right">
                    <button type="submit" className="bt cl-white">
                      Cadastrar{" "}
                      <img alt="" width="24" height="24" src={ArrowYellow} />
                    </button>
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="notice">
        <div className="content">
          <div className="row">
            {blogPosts &&
              blogPosts.map((post) => {
                return (
                  <div key={post.id} className="mb-3 col-lg-4 col-md-12">
                    <a
                      className="nav-link"
                      href={post.postLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="box">
                        <img alt={post.imgAlt} src={post.imgURL} />
                        <div className="texts">
                          <span className="cl-light-blue f-weight-700 font-32">
                            {post.title}
                          </span>
                          <PrismicRichText
                            field={post.excerpt}
                            components={{
                              paragraph: ({ children, node }) => (
                                <p key={node.text} className="cl-gray font-16">
                                  {children}
                                </p>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div id="video">
        <div className="content">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              {!centerContent.showCenterVideoBoolean &&
              centerContent.centerVideoURL !== "" ? (
                <div className="video-box">
                  <iframe
                    title="video"
                    width="100%"
                    height="100%"
                    src={centerContent.centerVideoURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <div>
                  <img
                    src={centerContent.centerImgURL}
                    alt={
                      centerContent.altCenterImgURL !== undefined
                        ? centerContent.altCenterImgURL
                        : ""
                    }
                  />
                </div>
              )}
            </div>
            <div className="col-lg-4 col-md-12 texts">
              <span className="cl-balck font-32 f-weight-700">
                {centerContent.centerTitle}
                {/* Quer ter um Super Faturamento extra? */}
              </span>
              <PrismicRichText
                field={centerContent.centerText}
                components={{
                  paragraph: ({ children, node }) => (
                    <p
                      key={node.text}
                      className="center-paragraph cl-black font-18"
                    >
                      {children}
                    </p>
                  ),
                }}
              />

              <a
                href={centerContent.btnLink}
                className="cl-blue border-blue font-16 bt"
              >
                {centerContent.btnText} <img alt="" src={ArrowDown} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="icons">
        <BrowserView>
          <div className="content">
            <div className="row">
              {icons &&
                React.Children.toArray(
                  icons.map((icon) => {
                    return (
                      <div className="col-4">
                        <div className="box">
                          <img alt={icon.iconAlt} src={icon.iconURL} />
                        </div>
                      </div>
                    );
                  })
                )}
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <Carousel
            showStatus={false}
            showIndicators={false}
            autoPlay={true}
            swipeable={true}
            emulateTouch={true}
            stopOnHover={true}
            infiniteLoop
            centerMode
            centerSlidePercentage={60}
            showThumbs={false}
          >
            {icons &&
              React.Children.toArray(
                icons.map((icon) => {
                  return (
                    <div className="col-4">
                      <div className="box">
                        <img alt={icon.iconAlt} src={icon.iconURL} />
                      </div>
                    </div>
                  );
                })
              )}
          </Carousel>
        </MobileView>
      </div>

      <div id="contatoa"></div>
    </div>
  );
}
