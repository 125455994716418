import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import Modal from "../../Components/Modal/index";

import "./Credenciados.scss";

import { BrowserView, MobileView } from "react-device-detect";

import iconTel from "../../images/credenciados/icon-tel.svg";
import iconLoc from "../../images/credenciados/icon-loc.svg";

import ModalImage from "../../images/modal/image-modal.png";

import { partners } from "./CredenciadosList.js";

import { useSinglePrismicDocument } from "@prismicio/react";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index(props) {
  const [modalFtActive, setModalFtActive] = useState(false);
  const [result, setResult] = useState([]);
  const [cloneResult, setCloneResult] = useState([]);
  const [estados, setEstados] = useState([]);
  const [filter, setFilter] = useState({
    uf: "",
  });

  const superPetPageGraphQuery = `{
        pagina_de_credenciados{
          image_footer
            image_footer_mobile
            text_footer
            btn_text_footer
            logo_img
            aside_logo_img
            intro_text
            label_uf_select
            new_partners
            removed_partners
        }
    }`.replace(/^ +| +$/gm, "");
  const [partnersPageContent] = useSinglePrismicDocument(
    "pagina_de_credenciados",
    {
      graphQuery: superPetPageGraphQuery,
    }
  );

  const propsBannerComponent = {
    imageFooter: partnersPageContent?.data.image_footer?.url
      ? partnersPageContent?.data.image_footer.url
      : "",
    imageFooterAlt: partnersPageContent?.data.image_footer?.alt
      ? partnersPageContent?.data.image_footer.alt
      : "",
    imageFooterMobile: partnersPageContent?.data.image_footer_mobile?.url
      ? partnersPageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: partnersPageContent?.data.image_footer_mobile?.alt
      ? partnersPageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: partnersPageContent?.data.text_footer[0]?.text
      ? partnersPageContent?.data.text_footer[0].text
      : "",
    btnText: partnersPageContent?.data.btn_text_footer[0]?.text
      ? partnersPageContent?.data.btn_text_footer[0].text
      : "",
    modalControl: setModalFtActive,
  };

  const mainContent = {
    logoImg: partnersPageContent?.data.logo_img?.url
      ? partnersPageContent?.data.logo_img.url
      : "",
    imgAsideLogo: partnersPageContent?.data.aside_logo_img?.url
      ? partnersPageContent?.data.aside_logo_img.url
      : "",
    imgFooter: partnersPageContent?.data.footer_img?.url
      ? partnersPageContent?.data.footer_img.url
      : "",
    textIntro: partnersPageContent?.data.intro_text[0]?.text
      ? partnersPageContent.data.intro_text[0].text
      : "",
    labelForSelect: partnersPageContent?.data.label_uf_select[0]?.text
      ? partnersPageContent.data.label_uf_select[0].text
      : "",
  };

  const updatedPartnersListMemoized = useMemo(() => {
    return {
      newPartners: partnersPageContent?.data.new_partners.map((partner) => {
        return {
          Nome: partner.partner_name[0]?.text
            ? partner.partner_name[0].text
            : "",
          "Nome Fantasia": partner.partner_fake_name[0]?.text
            ? partner.partner_fake_name[0].text
            : "",
          Logradouro: partner.street_address[0]?.text
            ? partner.street_address[0].text
            : "",
          Número: partner.number_address[0]?.text
            ? partner.number_address[0].text
            : "",
          Bairro: partner.neighborhood_address[0]?.text
            ? partner.neighborhood_address[0].text
            : "",
          Complemento: "",
          Cidade: partner.city_address[0]?.text
            ? partner.city_address[0].text
            : "",
          UF: partner.uf,
          Telefone: partner.tel[0]?.text ? partner.tel[0].text : "",
          Site: "",
        };
      }),
      removedPartners: partnersPageContent?.data.removed_partners.map(
        (partner) => {
          return {
            name: partner.removed_partner[0]?.text
              ? partner.removed_partner[0].text
              : "",
          };
        }
      ),
    };
  }, [partnersPageContent]);

  //esse side-effect mantém atualizada a lista de credenciados.
  useEffect(() => {
    let partnersList = [...partners];

    if (updatedPartnersListMemoized.newPartners !== undefined) {
      updatedPartnersListMemoized.newPartners.forEach((newPartner) => {
        if (partnersList.length > 0) {
          partnersList.push(newPartner);
        }
      });
    }
    if (updatedPartnersListMemoized.removedPartners !== undefined) {
      updatedPartnersListMemoized.removedPartners.forEach((removedPartner) => {
        if (partnersList.length > 0) {
          partnersList = partnersList.filter(
            (partner) => partner["Nome Fantasia"] !== removedPartner.name
          );
        }
      });
    }

    setResult(partnersList);
    setCloneResult(partnersList);
  }, [updatedPartnersListMemoized]);

  useEffect(() => {
    function getEstados() {
      var ufs = [];
      cloneResult.forEach((item) => {
        if (!ufs.includes(item["UF"])) {
          ufs.push(item["UF"]);
        }
      });
      setEstados(ufs);
    }
    getEstados();
  }, [cloneResult]);

  function handleFilter(e) {
    if (e.target.value === "Todos os estados") {
      setResult(cloneResult);
      setFilter(e.target.value);
      return;
    }
    const uf = e.target.value;
    setFilter({ ...filter, uf });

    let filtered = cloneResult.filter((e) => e["UF"] === uf);
    setResult(filtered);
  }

  return (
    <div id="credenciados">
      <div id="head"></div>
      <div id="search">
        <div className="content">
          <BrowserView>
            <div className="row">
              <div className="col-6 text">
                <img src={mainContent.logoImg} alt="" />
                <p className="cl-black font-32 f-weight-700">
                  {mainContent.textIntro}
                </p>
                <form>
                  <label className="cl-black font-16" htmlFor="partners">
                    {mainContent.labelForSelect}
                  </label>
                  <select
                    id="partners"
                    name="uf"
                    value={filter.uf}
                    onChange={(e) => handleFilter(e)}
                  >
                    <option value="Todos os estados">Todos os estados</option>
                    {React.Children.toArray(
                      estados.map((uf) => <option value={uf}>{uf}</option>)
                    )}
                  </select>
                </form>
              </div>
              <div className="col-6 images">
                <img className="image" src={mainContent.imgAsideLogo} alt="" />
              </div>
            </div>
          </BrowserView>
        </div>
        <MobileView>
          <div className="row">
            <div className="col-12 images" align="center">
              <img src={mainContent.logoImg} alt="" />
              <img className="image" src={mainContent.imgAsideLogo} alt="" />
            </div>
            <div className="col-12 text">
              <span className="cl-black font-32 f-weight-700">
                {mainContent.textIntro}
              </span>
              <form>
                <label
                  className="cl-black font-16 col-12"
                  htmlFor="partners-mobile"
                >
                  {mainContent.labelForSelect}
                </label>
                <select
                  id="partners-mobile"
                  name="uf"
                  value={filter.uf}
                  onChange={(e) => handleFilter(e)}
                >
                  <option className="font-12" value="Todos os estados">
                    Todos os estados
                  </option>
                  {React.Children.toArray(
                    estados.map((uf) => <option value={uf}>{uf}</option>)
                  )}
                </select>
              </form>
            </div>
          </div>
        </MobileView>
      </div>
      <div id="results">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <p className="font-16 cl-black res">Resultados encontrados:</p>
            </div>
          </div>

          <div className="row boxes">
            {React.Children.toArray(
              result.map((item) => (
                <div className="col-lg-6 col-md-12">
                  <div className="box">
                    <span className="font-18 cl-black f-weight-700">
                      {item["Nome Fantasia"]}
                    </span>
                    <span className="tel font-16 cl-light-blue">
                      <img src={iconTel} alt="" /> {item["Telefone"]}
                    </span>
                    <span className="loc font-16 cl-end-gray">
                      <img src={iconLoc} alt="" />
                      {item["Logradouro"]}, {item["Número"]} – {item["Bairro"]}{" "}
                      <br />
                      {item["Cidade"]} – {item["UF"]}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>
      </Modal>
    </div>
  );
}
