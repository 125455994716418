import React, { useState } from "react";

import Header from "./Header";
import Footer from "./Footer";
import { useSinglePrismicDocument } from "@prismicio/react";

export default function Layout(props) {
  const [modalFtActive, setModalFtActive] = useState(false);

  const handleActivateModal = (value) => {
    setModalFtActive(value);
  };

  const phonesGraphQuery = `{
    telefones{
        phones_footer
        phones_auto
        phones_health
        phones_home
        phones_dental
        phones_pet
        whats_number
      }
}`.replace(/^ +| +$/gm, "");

  const [phonesContent] = useSinglePrismicDocument("telefones", {
    graphQuery: phonesGraphQuery,
  });

  const phonesData = {
    phonesFooter: phonesContent?.data?.phones_footer?.map((data) => {
      return {
        title: data.title[0]?.text ? data.title[0].text : "",
        numberRaw: data.number[0]?.text ? data.number[0].text.split("|") : "",
        number: data.number[0]?.text
          ? data.number[0].text.includes("|")
            ? data.number[0].text
                .split("|")
                .map((number) => number.replace(/\D/g, ""))
            : data.number[0].text.replace(/\D/g, "")
          : "",
      };
    }),
    whatsNumber: phonesContent?.data?.whats_number[0]?.text
      ? phonesContent.data.whats_number[0].text
      : "",
    modal: {
      phonesAuto: phonesContent?.data?.phones_auto?.map((data) => {
        return {
          title: data.title[0]?.text ? data.title[0].text : "",
          numberRaw: data.number[0]?.text ? data.number[0].text : "",
          number: data.number[0]?.text
            ? data.number[0].text.replace(/\D/g, "")
            : "",
        };
      }),

      phonesHealth: phonesContent?.data?.phones_health?.map((data) => {
        return {
          title: data.title[0]?.text ? data.title[0].text : "",
          numberRaw: data.number[0]?.text ? data.number[0].text : "",
          number: data.number[0]?.text
            ? data.number[0].text.replace(/\D/g, "")
            : "",
        };
      }),

      phonesHome: phonesContent?.data?.phones_home?.map((data) => {
        return {
          title: data.title[0]?.text ? data.title[0].text : "",
          numberRaw: data.number[0]?.text ? data.number[0].text : "",
          number: data.number[0]?.text
            ? data.number[0].text.replace(/\D/g, "")
            : "",
        };
      }),

      phonesDental: phonesContent?.data?.phones_dental?.map((data) => {
        return {
          title: data.title[0]?.text ? data.title[0].text : "",
          numberRaw: data.number[0]?.text ? data.number[0].text : "",
          number: data.number[0]?.text
            ? data.number[0].text.replace(/\D/g, "")
            : "",
        };
      }),

      phonesPet: phonesContent?.data?.phones_pet?.map((data) => {
        return {
          title: data.title[0]?.text ? data.title[0].text : "",
          numberRaw: data.number[0]?.text ? data.number[0].text : "",
          number: data.number[0]?.text
            ? data.number[0].text.replace(/\D/g, "")
            : "",
        };
      }),
    },
  };

  return (
    <>
      <Header
        phonesData={phonesData}
        modalFtActive={modalFtActive}
        setModalFtActive={handleActivateModal}
      />
      {props.children}
      <Footer phonesData={phonesData} setModalFtActive={handleActivateModal} />
    </>
  );
}
