import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { BrowserView, MobileView } from "react-device-detect";
import LazyLoad from "react-lazyload";

import ArrowBlue from "../../images/icon/arrow-blue.svg";
import "./banner-footer.scss";

export function BannerFooter({ props }) {
  return (
    <>
      <BrowserView>
        <LazyLoad>
          <div id="solicite">
            <div className="content">
              <ScrollAnimation animateOnce animateIn="fadeIn">
                <img
                  alt={props.imageFooterAlt || ""}
                  className="bg-slgrenn img-footer"
                  src={props.imageFooter}
                />
              </ScrollAnimation>
              <div className="row">
                <div className="text-footer col-lg-4 col-md-12 left-sl">
                  <p className="cl-white font-64 f-weight-700">
                    {props.textFooter}
                  </p>
                  <button
                    onClick={(e) => props.modalControl(true)}
                    className="cl-blue border-yellow bt font-18"
                  >
                    {props.btnText}{" "}
                    <img
                      className="icon"
                      width="24"
                      height="25"
                      alt=""
                      src={ArrowBlue}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
      </BrowserView>
      <MobileView>
        <LazyLoad>
          <div id="solicite">
            <div className="content">
              <div className="row">
                <div className="col-lg-8 col-md-12 left-sr left-srot">
                  <ScrollAnimation animateOnce animateIn="fadeIn">
                    <img
                      alt={props.imageFooterMobileAlt || ""}
                      className="img-mobile"
                      src={props.imageFooterMobile}
                    />
                  </ScrollAnimation>
                  <div className="row">
                    <div className="text-footer col-lg-4 col-md-12 left-sl">
                      <p className="cl-white font-48 f-weight-700">
                        {props.textFooter}
                      </p>
                      <button
                        onClick={(e) => props.modalControl(true)}
                        className="cl-blue border-yellow bt font-18"
                      >
                        {props.btnText}{" "}
                        <img
                          className="icon"
                          width="24"
                          height="25"
                          alt=""
                          src={ArrowBlue}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoad>
      </MobileView>
    </>
  );
}
