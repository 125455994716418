import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useSinglePrismicDocument } from "@prismicio/react";

import downlaodGooglePlay from "../../images/descontos/downlaod-google.png";
import downlaodAppStore from "../../images/descontos/downlaod-apple.png";

import arrowDown from "../../images/descontos/arrow-down.png";

import logoFooter from "../../images/descontos/logo-footer.png";
import SeloFooter from "../../images/selo.png";

import { Carousel } from "react-responsive-carousel";

import "./Desconto.scss";
import { BrowserView, MobileView } from "react-device-detect";

export default function Index() {
  const descontosPageGraphQuery = `{
    descontos{
      descontos
      image_banner
      image_download
      link_app_store
      link_google_play
      motivos
      parceiros
      subtitulo
      texto_download
      titulo
    }
}`.replace(/^ +| +$/gm, "");
  const [descontosPageContent] = useSinglePrismicDocument("descontos", {
    graphQuery: descontosPageGraphQuery,
  });

  const mainContent = {
    titulo: descontosPageContent?.data.titulo[0]?.text
      ? descontosPageContent.data.titulo[0]?.text
      : "",
    subtitulo: descontosPageContent?.data.subtitulo[0]?.text
      ? descontosPageContent.data.subtitulo[0]?.text
      : "",
    texto_download: descontosPageContent?.data.texto_download[0]?.text
      ? descontosPageContent.data.texto_download[0]?.text
      : "",
    image_banner: descontosPageContent?.data.image_banner?.url
      ? descontosPageContent?.data.image_banner.url
      : "",
    image_download: descontosPageContent?.data.image_download?.url
      ? descontosPageContent?.data.image_download.url
      : "",
    link_app_store: descontosPageContent?.data.link_app_store?.url
      ? descontosPageContent?.data.link_app_store.url
      : "",
    link_google_play: descontosPageContent?.data.link_google_play?.url
      ? descontosPageContent?.data.link_google_play.url
      : "",
  };

  const descontos = descontosPageContent?.data.descontos?.map((plan) => {
    return {
      imagem_descontos: plan.imagem_descontos?.url
        ? plan.imagem_descontos?.url
        : "",
      link_descontos: plan.link_descontos?.url ? plan.link_descontos?.url : "",
    };
  });

  const motivos = descontosPageContent?.data.motivos?.map((plan) => {
    return {
      icone: plan.icone?.url ? plan.icone?.url : "",
      texto_motivo: plan.texto_motivo[0]?.text
        ? plan.texto_motivo[0]?.text
        : "",
      titulo_motivos: plan.titulo_motivos[0]?.text
        ? plan.titulo_motivos[0]?.text
        : "",
    };
  });

  const parceiros = descontosPageContent?.data.parceiros?.map((plan) => {
    return {
      imagem_parceiros: plan.imagem_parceiros?.url
        ? plan.imagem_parceiros?.url
        : "",
    };
  });

  return (
    <div id="desconto">
      <div id="banner">
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex justify-content-center">
              <img src={mainContent.image_banner} alt="Vantagens" />
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center flex-column pl-5 text-center text-lg-left mb-4 mb-lg-0">
              <h1>{mainContent.titulo}</h1>
              <p>{mainContent.subtitulo}</p>
            </div>
            <div className="col-12 ">
              <div className="click-down d-none d-lg-flex">
                <AnchorLink
                  offset="100"
                  href="#motivos"
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={arrowDown} alt="" />
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="motivos">
        <div className="content">
          <div className="row">
            <div className="col-12 title text-center">
              <h3>Confira 4 motivos</h3>
              <p>Para fazer parte do clube exclusivo de vantagens</p>
            </div>
          </div>
          <div className="row">
            {motivos &&
              React.Children.toArray(
                motivos.map((plan) => {
                  return (
                    <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                      <div className="box-motivos">
                        <img src={plan.icone} alt="icon"></img>
                        <span>{plan.titulo_motivos}</span>
                        <p>{plan.texto_motivo}</p>
                      </div>
                    </div>
                  );
                })
              )}
          </div>
        </div>
      </div>

      <div id="download">
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-6  d-flex align-items-center justify-content-center">
              <img src={mainContent.image_download} alt="Vantagens" />
            </div>
            <div className="col-12 col-lg-6  d-flex align-items-start justify-content-center flex-column text-center text-lg-left">
              <h2>{mainContent.texto_download}</h2>
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start flex-row images mb-4 mb-lg-0 w-100">
                <a href={mainContent.link_google_play}>
                  <img src={downlaodGooglePlay} alt="Downlaod Google Play" />
                </a>
                <a href={mainContent.link_app_store}>
                  <img src={downlaodAppStore} alt="Downlaod App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileView>
        <div id="descontos">
          <div className="content">
            <h4>Descontos do mês</h4>
            <Carousel
              showStatus={false}
              autoPlay={true}
              swipeable={true}
              emulateTouch={true}
              stopOnHover={true}
              infiniteLoop={true}
              renderIndicator={false}
              showThumbs={false}
              centerMode={true}
              centerSlidePercentage={50}
              interval={2000}
            >
              {descontos &&
                React.Children.toArray(
                  descontos.map((plan) => {
                    return (
                      <div className="box-carousel">
                        <a href={plan.link_descontos}>
                          <img src={plan.imagem_descontos} alt="desconto" />
                        </a>
                      </div>
                    );
                  })
                )}
            </Carousel>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div id="descontos">
          <div className="content">
            <h4>Descontos do mês</h4>
            <Carousel
              showStatus={false}
              autoPlay={true}
              swipeable={true}
              emulateTouch={true}
              stopOnHover={true}
              infiniteLoop={true}
              renderIndicator={false}
              showThumbs={false}
              centerMode={true}
              centerSlidePercentage={30}
              interval={2000}
            >
              {descontos &&
                React.Children.toArray(
                  descontos.map((plan) => {
                    return (
                      <div className="box-carousel">
                        <a href={plan.link_descontos}>
                          <img src={plan.imagem_descontos} alt="desconto" />
                        </a>
                      </div>
                    );
                  })
                )}
            </Carousel>
          </div>
        </div>
      </BrowserView>

      <div id="parceiros">
        <div className="content">
          <div className="row">
            <div className="col-12 text-center">
              <h2>
                Confira alguns dos nossos <br /> principais parceiros
              </h2>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center flex-row flex-wrap">
              {parceiros &&
                React.Children.toArray(
                  parceiros.map((plan) => {
                    return <img src={plan.imagem_parceiros} alt="parceiro" />;
                  })
                )}
            </div>
          </div>
        </div>
      </div>

      <div id="footer-descontos">
        <div className="content">
          <div className="row line-1">
            <div className="col-12 col-lg-3 d-flex align-items-center justify-content-center mb-4 mb-lg-0">
              <Link to="/">
                <img src={logoFooter} alt="Logo Footer" />
              </Link>
            </div>
            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center links mb-4 mb-lg-0">
              <a href="/">Termos de uso</a>
              <a href="/">Perguntas Frequentes</a>
            </div>
            <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center flex-column">
              <a href="/garantia">
                <img src={SeloFooter} alt="Selo FGRS" className="mb-4" />
              </a>              <div className="d-flex align-items-center justify-content-center flex-row images">
                <a href={mainContent.link_google_play}>
                  <img src={downlaodGooglePlay} alt="Downlaod Google Play" />
                </a>
                <a href={mainContent.link_app_store}>
                  <img src={downlaodAppStore} alt="Downlaod App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="row line-2">
            <div className="col-12 text-center text-lg-left">
              <span>Super 2022 - Todos os direitos reservados.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
