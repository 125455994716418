import { PrismicRichText } from "@prismicio/react";
import React from "react";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import "./styles.scss";

export function NewPlans({ arrayNewPlans }) {
  return (
    <div id="links">
      <div className="content">
        <div className="row">
          <div className="col-12" align="center">
            <span className="font-25 cl-black">
              Temos novidades vindo por aí,{" "}
              <b>para cuidar do que realmente importa.</b>
            </span>
          </div>
          <div className="col-12 row no-gutters">
            {arrayNewPlans.length > 0 &&
              arrayNewPlans.map((card) => {
                return (
                  <div
                    key={card.plan_image.url}
                    className="col-lg-6 col-md-12 al-center"
                  >
                    <div className="box row">
                      <div className="col-lg-6 col-md-12">
                        <img alt="" src={card.plan_image.url} />
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <span className="cl-gray font-40">
                          <PrismicRichText
                            field={card.plan_name}
                            components={{
                              paragraph: ({ children, node }) => (
                                <span key={node.text} className="plan-name">
                                  {children}
                                </span>
                              ),
                            }}
                          />
                        </span>
                        <p className="cl-gray font-18">
                          <PrismicRichText
                            field={card.text}
                            components={{
                              paragraph: ({ children, node }) => (
                                <span key={node.text} className="plan-text">
                                  {children}
                                </span>
                              ),
                            }}
                          />
                        </p>
                        <a
                          href={card.btn_link[0].text}
                          className="cl-white bg-light-blue font-18 bt"
                        >
                          Saiba mais{" "}
                          <img
                            width="24"
                            height="24"
                            alt=""
                            src={ArrowYellow}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
