import React, { useState } from "react";
import { Link } from "react-router-dom";
import FaqCp from "react-faq-component";
import BannerImage from "../../images/garantia/banner-image.png";
import Image1 from "../../images/garantia/image-1.png";
import Image2 from "../../images/garantia/image-2.png";
import logoFooter from "../../images/descontos/logo-footer.png";
import downlaodGooglePlay from "../../images/descontos/downlaod-google.png";
import downlaodAppStore from "../../images/descontos/downlaod-apple.png";
import { useSinglePrismicDocument } from "@prismicio/react";
import SeloFooter from "../../images/selo.png";

import ArrowOpen from '../../images/sobre/Seta_aberta.svg';

import "./Garantia.scss";
import { BrowserView, MobileView } from "react-device-detect";

export default function Index() {

  const [faq, setFaqList] = useState([]);

  const data = {
    rows: [
      {
        title: "O QUE É O FGRS?",
        content: "O Fundo Garantidor contra Riscos Sistêmicos (FGRS) é uma associação civil sem fins lucrativos, com personalidade jurídica de direito privado, instituída e mantida pela Agência de Autorregulamentação das Entidades de Autogestão de Planos de Proteção contra Riscos Patrimoniais (AAAPV).",
      },
      {
        title: "QUAL A FINALIDADE DO FGRS?",
        content: "Proteger, no caso de insolvência de uma Entidade de Autogestão de Planos de Proteção contra Riscos Patrimoniais (EAPP) filiada, os associados e/ou cooperados que utilizam o sistema proteção mútua para cobertura de seu patrimônio. Na prática, caso uma EAPP filiada entre em estado de insolvência, o FGRS atuará cobrindo os eventos de perda parcial, total ou roubo/furto que estejam abertos e ainda não indenizados, sempre agindo de acordo com os limites de garantia ordinária previstos no Regulamento Interno.",
      },
      {
        title: "QUAIS OS BENEFÍCIOS QUE O FGRS TRAZ AO MERCADO?",
        content: "Segurança para todos os envolvidos no sistema de Proteção Mútua do Brasil. Com o advento do fundo, os associados e/ou cooperados das EAPPs filiadas possuem uma garantia necessária, porém inédita nesse segmento, o que trás mais credibilidade e melhora a percepção do mercado com relação às Mútuas.",
      },
      {
        title: "QUAL O VALOR DAS CONTRIBUIÇÕES FEITAS AO FGRS?",
        content: "O fator de contribuição é de 0,00525% sobre o valor de tabela FIPE do veículo. Exemplificando: um automóvel avaliado em R$ 30.000,00 (trinta mil reais) terá a contribuição estipulada em R$ 1,57 (um real e cinquenta e sete centavos).",
      },
      {
        title: "ENQUANTO NÃO HOUVER UTILIZAÇÃO DO FGRS, O QUE SERÁ FEITO COM OS RECURSOS EM CAIXA?",
        content: "Os valores disponíveis em caixa serão rentabilizados através de um fundo de investimentos do qual o FGRS é o titular de todas as quotas. A política de investimentos adotada está prevista no Estatuto Social do FGRS e deverá ser seguida à risca.",
      },
      {
        title: "QUAL A LIGAÇÃO DO FGRS COM A AAAPV?",
        content: "Embora sejam duas instituições distintas, a AAAPV é a criadora e mantenedora do FGRS, por esse motivo, somente serão aceitos no FGRS EAPPs que sejam filiadas à AAAPV.",
      },
      {
        title: "O FGRS ATUARÁ SOMENTE EM CASOS DE INSOLVÊNCIA?",
        content: "Na cobertura das garantias ordinárias, sim. O FGRS também realizará o monitoramento das EAPPs filiadas com o intuito de acompanhar a saúde financeira e propor medidas a serem adotadas com o intuito de reduzir os riscos de insolvência.",
      },
      {
        title: "QUEM DECLARA A INSOLVÊNCIA DE UM EAPP FILIADA?",
        content: "A própria EAPP filiada. Obviamente, o FGRS terá à sua disposição ferramentas que possibilitem saber que determinada EAPP está indo para um caminho de risco, mas a função do fundo, nesse caso, será de advertir a EAPP para que a insolvência seja evitada.",
      },
      {
        title: "QUAIS MECANISMOS DE DEFESA CONTRA FRAUDES O FGRS TEM A SUA DISPOSÇÃO?",
        content: "Como já citado anteriormente o FGRS terá, à sua disposição, ferramentas que possibilitarão o acompanhamento da saúde da operação das EAPPs filiadas, tendo acesso aos dados necessários para realizar o Cálculo de Probabilidade de Ruína. Além disso, haverá um corpo técnico com profissionais capacitados para analisar e auditar esses dados e, dessa forma, prevenir fraudes. O FGRS também pode, de acordo com seu Regulamento Interno, instalar auditoria a qualquer tempo para apurar as operações da EAPP filiada no que diz respeito à sua saúde financeira e risco de insolvência. O FGRS, detectando uma conduta que coloque em risco a operação da EAPP filiada poderá, com base em critérios técnicos, advertir a mesma e indicar uma mudança de postura. Caso a EAPP não adote as recomendações do FGRS e continue agindo de maneira a colocar em risco sua operação, o FGRS poderá, seguindo as normas estatutárias e regimentais, proceder com a exclusão da EAPP, cabendo recurso e direito ao contraditório a ser exercido pela mesma.",
      },
      {
        title: "O FGRS TEM PODER DE INTERVENÇÃO, COMO POR EXEMPLO O DE DECRETAR DIREÇÃO FISCAL NUMA EAPP FILIADA?",
        content: "Não. O FGRS pode auditar, advertir, instruir e até mesmo excluir, mas nunca interferir de forma ativa na gestão de uma EAPP filiada, a não ser que a EAPP em questão procure o FGRS para declarar sua insolvência e acionar o mesmo para que os seus associados e/ou cooperados com eventos abertos possam receber as garantias ordinárias, respeitando os limites para cada tipo de evento e veículo estipulados no Regulamento Interno.",
      },
      {
        title: "APÓS DETERMINADO TEMPO CONTRIBUINDO, AS EAPP FILIADAS PODERÃO EFETUAR RESGATES DOS VALORES DEPOSITADOS NO FGRS?",
        content: "Não. O FGRS é constituído para amparar os associados e/ou cooperados no caso de insolvência. As contribuições são feitas nesse sentido e o objetivo não é se tornar um investimento. O FGRS é similar às próprias mútuas filiadas nesse aspecto: quando um associado e/ou cooperado deseja se desligar ou passa algum tempo sem utilizar os benefícios da proteção mútua, o mesmo não possui o direito de solicitar o recebimento dos valores já pagos, pois durante o período em que ele contribuiu, ele estava amparado. A situação é a mesma.",
      },
      {
        title: "AS MÚTUAS VÃO CONTRIBUIR ETERNAMENTE PAR AO FGRS?",
        content: "Não. De acordo com o Regulamento, quando o fundo atingir 2% da FIPE EXPOSTA, as contribuições serão interrompidas e assim permanecerão enquanto o nível se mantiver em 2% da FIPE exposta.",
      },
      {
        title: "PODERÁ HAVER UM OUTRO DESTINO PARA O FUNDO NO FUTURO?",
        content: "Sim. A intenção dos fundadores - e isso ficou consignado na ata de fundação - é que, num futuro a médio prazo, quando o FGRS estiver suficientemente robusto ele se transforme em uma resseguradora. Então, cada participante será acionista dessa futura sociedade anônima, proporcionalmente ao seu quinhão de contribuições vertidas para o FGRS.",
      }
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "6C6C6C",
    rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    arrowIcon: <img alt="" src={ArrowOpen} />,
    // tabFocus: true
  };

  const descontosPageGraphQuery = `{
    descontos{
      link_app_store
      link_google_play
    }
}`.replace(/^ +| +$/gm, "");
  const [descontosPageContent] = useSinglePrismicDocument("descontos", {
    graphQuery: descontosPageGraphQuery,
  });

  const mainContent = {
    link_app_store: descontosPageContent?.data.link_app_store?.url
      ? descontosPageContent?.data.link_app_store.url
      : "",
    link_google_play: descontosPageContent?.data.link_google_play?.url
      ? descontosPageContent?.data.link_google_play.url
      : "",
  };

  return (
    <div id="garantia">
      <div id="banner">
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-4 d-flex justify-content-center flex-column">
              <h1>
                De que forma o FGRS <br className="d-none d-lg-block" />
                garante muito mais <br className="d-none d-lg-block" />
                proteção ao Associado <br className="d-none d-lg-block" />
                Super Benefícios
              </h1>
              <p>
                A Super Benefícios tem como premissa o compromisso com o Associado. Como meio de garantir ainda mais proteção, nós fazemos parte do Fundo Garantidor contra Riscos <br className="d-none d-lg-block" />Sistêmicos (FGRS).
              </p>
            </div>
            <div className="col-12 col-lg-8 d-flex justify-content-center flex-column pl-5 text-center text-lg-left mb-4 mb-lg-0">
              <img src={BannerImage} alt="Garantia Super" />
            </div>
          </div>
        </div>
      </div>

      <div id="section1">
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-6 d-none d-lg-block">
              <img src={Image1} alt="O que é" />
            </div>
            <div className="col-12 col-lg-6">
              <h2>
                O que é o FGRS e como ele<br className="d-none d-lg-block" /> garante mais proteção ao<br className="d-none d-lg-block" /> Associado Super
              </h2>
              <p>
                O FGRS é uma iniciativa da Agência de Autorregulamentação das<br className="d-none d-lg-block" /> Entidades de Autogestão de Planos de Proteção Contra Riscos<br className="d-none d-lg-block" /> Patrimoniais (AAAPV). O fundo atua como mais um consolidador para<br className="d-none d-lg-block" /> garantir os direitos dos associados, e atua mutuamente no segmento<br className="d-none d-lg-block" /> de proteção patrimonial no Brasil.<br /><br />

                Além disso, o FGRS tem como missão amparar o associado/cooperado<br className="d-none d-lg-block" /> no caso de insolvência de alguma mútua filiada.<br /><br />

                Todas as nossas Entidades de Autogestão de Planos de Proteção<br className="d-none d-lg-block" /> contra Riscos Patrimoniais (EAPP) realizam uma contribuição mensal,<br className="d-none d-lg-block" /> além disso, são acompanhadas e auditadas com frequência. Elas<br className="d-none d-lg-block" /> precisam seguir regras de compliance e de gestão estabelecidas pela<br className="d-none d-lg-block" /> AAAPV e pelo FGRS para que possam receber e manter o selo.
              </p>
            </div>
            <div className="col-12 col-lg-6 d-block d-lg-none text-center">
              <img src={Image1} alt="O que é" className="mt-5" />
            </div>
          </div>
        </div>
      </div>

      <div id="section2">
        <div className="content">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h3>
                Como é feito o monitoramento<br className="d-none d-lg-block" />
                dos afiliados FGRS
              </h3>
              <p>
                Frequentemente é realizado o monitoramento nas entidades filiadas e<br className="d-none d-lg-block" /> quando detectado conduta inadequada de alguma mútua que possa<br className="d-none d-lg-block" /> causar risco a alguma operação, o FGRS atua para fazer orientações a<br className="d-none d-lg-block" /> fim de que a mesma execute ajustes em suas ações para evitar que<br className="d-none d-lg-block" /> ocorra qualquer insolvência. <br /><br />

                E, caso, o pior aconteça, o FGRS cobre, dentro dos limites<br className="d-none d-lg-block" /> estabelecidos no regulamento do fundo, os eventos que estiverem<br className="d-none d-lg-block" /> abertos, sejam perdas parcial, total ou roubo/furto. Protegendo e<br className="d-none d-lg-block" /> garantido ao associado acesso ao serviço contratado.
              </p>
              <a className="button-yellow" href="../../certificado.pdf" target="_blank">Veja nosso certificado</a>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <img src={Image2} alt="Como é feito" />
            </div>
          </div>
        </div>
      </div>

      <div id="faq">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <hr />
              <span>FAQ</span>
              <h4>Dúvidas Frequentes</h4>


              <FaqCp data={data} styles={styles} config={config} />

            </div>
          </div>
        </div>
      </div>

      <div id="banner-footer">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="box d-flex align-items-end justify-content-center">
                <div className="mini-box">
                  <p>Quer saber mais?</p>
                  <a href="https://api.whatsapp.com/send?phone=551140035542" target="_blank" className="button-yellow">
                    Fale com nossos especialistas!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer-descontos">
        <div className="content">
          <div className="row line-1">
            <div className="col-12 col-lg-3 d-flex align-items-center justify-content-center mb-4 mb-lg-0">
              <Link to="/">
                <img src={logoFooter} alt="Logo Footer" />
              </Link>
            </div>
            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center links mb-4 mb-lg-0">
              <a href="/">Termos de uso</a>
              <a href="/">Perguntas Frequentes</a>
            </div>
            <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center flex-column">
              <a href="/garantia">
                <img src={SeloFooter} alt="Selo FGRS" className="mb-4" />
              </a>
              <div className="d-flex align-items-center justify-content-center flex-row images">
                <a href={mainContent.link_google_play}>
                  <img src={downlaodGooglePlay} alt="Downlaod Google Play" />
                </a>
                <a href={mainContent.link_app_store}>
                  <img src={downlaodAppStore} alt="Downlaod App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="row line-2">
            <div className="col-12 text-center text-lg-left">
              <span>Super 2022 - Todos os direitos reservados.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
