import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Index.scss";

import Modal from "../../Components/Modal/index";

import BgBanner1 from "../../images/backgrounds/banner-left.svg";
import BgBanner2 from "../../images/backgrounds/banner-right.svg";

import ArrowWhite from "../../images/icon/arrow-white.svg";
import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Heart from "../../images/icon/coracao-garante.svg";

import ModalImage from "../../images/modal/image-modal.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { BrowserView, MobileView } from "react-device-detect";
import LazyLoad from "react-lazyload";

import { useSinglePrismicDocument, PrismicRichText } from "@prismicio/react";

import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  // Query para consumir a API do Prismic

  const homePageGraphQuery = `{
        pagina_inicial{
          banner
          banner_mobile
          show_video
          video_url
          intro_img
          intro_img_mobile
          image_footer_mobile
          footer_img
          footer_text
          btn_text_footer
          title
          text_intro
          link_contact_us
          title_benefit
          subtitle_benefit
          plans{
            plan_link{
              ...on planos{
                title
                subtitle
                short_text
                observation
                price
                btn_text
                btn_link
                plan_image
              }
            }
          }      
        }
    }`.replace(/^ +| +$/gm, "");
  const [homePageContent] = useSinglePrismicDocument("pagina_inicial", {
    graphQuery: homePageGraphQuery,
  });

  const carouselDesktop = homePageContent?.data.banner.map((banner) => {
    return {
      imageURL:
        banner.image_banner?.url !== null ? banner.image_banner.url : "#",
      imageAlt:
        banner.image_banner?.alt !== null ? banner.image_banner.alt : "",
      linkBanner: banner.link_banner[0]?.text
        ? banner.link_banner[0].text
        : "#",
    };
  });
  const carouselMobile = homePageContent?.data.banner_mobile.map((banner) => {
    return {
      imageURL:
        banner.image_banner_mobile?.url !== null
          ? banner.image_banner_mobile.url
          : "#",
      imageAlt:
        banner.image_banner_mobile?.alt !== null
          ? banner.image_banner_mobile.alt
          : "",
      linkBanner: banner.link_banner[0]?.text
        ? banner.link_banner[0].text
        : "#",
    };
  });
  //Conteúdo do prismic formatado em um objeto
  const mainContent = {
    showIntroVideoBoolean: homePageContent?.data.show_video,
    introImgURL:
      homePageContent?.data.intro_img.url !== null
        ? homePageContent?.data.intro_img.url
        : "/",
    altIntroImgURL:
      homePageContent?.data.intro_img.alt !== null
        ? homePageContent?.data.intro_img.alt
        : "",
    introImgMobileURL:
      homePageContent?.data.intro_img_mobile.url !== null
        ? homePageContent?.data.intro_img_mobile.url
        : "/",
    altIntroImgMobile:
      homePageContent?.data.intro_img_mobile.alt !== null
        ? homePageContent?.data.intro_img_mobile.alt
        : "",
    banner_carousel: homePageContent?.data.banner,
    banner_carousel_mobile: homePageContent?.data.banner_mobile,
    videoURL: homePageContent?.data.video_url
      ? homePageContent?.data.video_url[0].text
      : "",
    titleIntro: homePageContent?.data.title
      ? homePageContent?.data.title[0].text
      : "",
    textIntro: homePageContent?.data.text_intro
      ? homePageContent?.data.text_intro[0].text
      : "",
    link_contact_us: homePageContent?.data.link_contact_us
      ? homePageContent?.data.link_contact_us[0].text
      : "",
    titleBenefitSection: homePageContent?.data.title_benefit
      ? homePageContent?.data.title_benefit[0].text
      : "",
    subtitleBenefitSection: homePageContent?.data.subtitle_benefit,
  };

  //Conteúdo dos planos formatado em um objeto
  const plansFormatted = homePageContent?.data.plans.map((plan) => {
    return {
      id: plan.plan_link.id,
      imageURL: plan.plan_link.data?.plan_image.url,
      imageAlt: plan.plan_link.data?.plan_image.alt,
      title: plan.plan_link.data?.title,
      subtitle: plan.plan_link.data?.subtitle
        ? plan.plan_link.data.subtitle[0].text
        : "",
      short_text: plan.plan_link.data?.short_text
        ? plan.plan_link.data.short_text[0].text
        : "",
      observation: plan.plan_link.data?.observation
        ? plan.plan_link.data.observation[0].text
        : "",
      price: plan.plan_link.data?.price
        ? Number(
            plan.plan_link.data.price[0].text.replace(",", ".")
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        : "",
      btnText: plan.plan_link.data?.btn_text
        ? plan.plan_link.data.btn_text[0].text
        : "",
      btnLink: plan.plan_link.data?.btn_link
        ? plan.plan_link.data.btn_link[0].text
        : "",
    };
  });

  const propsBannerComponent = {
    imageFooter: homePageContent?.data.footer_img?.url
      ? homePageContent?.data.footer_img.url
      : "/",
    imageFooterAlt: homePageContent?.data.footer_img?.alt
      ? homePageContent?.data.footer_img.alt
      : "",
    imageFooterMobile: homePageContent?.data.image_footer_mobile?.url
      ? homePageContent?.data.image_footer_mobile.url
      : "/",
    imageFooterMobileAlt: homePageContent?.data.image_footer_mobile?.alt
      ? homePageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: homePageContent?.data.footer_text[0]?.text
      ? homePageContent?.data.footer_text[0].text
      : "",
    btnText: homePageContent?.data.btn_text_footer[0]?.text
      ? homePageContent?.data.btn_text_footer[0].text
      : "",
    modalControl: setModalFtActive,
  };

  return (
    <div id="home">
      <div className="top-canvas"></div>
      <div id="banner">
        <BrowserView>
          <img
            id="bg-banner1"
            width="1112"
            height="767"
            alt=""
            className="remove_animated fadeInLeft"
            src={BgBanner1}
          />
        </BrowserView>

        <BrowserView>
          <div className="carousel-images">
            {carouselDesktop && (
              <Carousel
                showStatus={false}
                autoPlay={true}
                showIndicators={false}
                swipeable={false}
                emulateTouch={true}
                thumbWidth={100}
                infiniteLoop
                showThumbs={false}
              >
                {carouselDesktop.length > 0 &&
                  React.Children.toArray(
                    carouselDesktop.map((item) => {
                      return (
                        <div className="box-carousel">
                          <a href={item.linkBanner || "#"}></a>
                          <img
                            className="image-carousel"
                            src={item.imageURL || "/"}
                            alt={item.imageAlt || ""}
                          />
                        </div>
                      );
                    })
                  )}
              </Carousel>
            )}
          </div>
          <div className="content">
            <div className="row no-gutters">
              <div className="mt-5 col-5 d-flex flex-column texto-banner">
                <div className="font-48 f-weight-500 cl-white">
                  <span className="cl-yellow f-weight-500">
                    <h1 className="col-10">{mainContent.titleIntro}</h1>
                  </span>
                </div>
                <div>
                  <p className="col-12 font-24 cl-white">
                    {mainContent.textIntro}
                  </p>
                </div>
                <a
                  href={mainContent.link_contact_us}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="bt border-white cl-white font-16"
                >
                  ou fale conosco no WhatsApp{" "}
                  <img
                    width="24"
                    height="24"
                    className="icon"
                    alt=""
                    src={ArrowWhite}
                  />
                </a>
              </div>
              <div className="col-7">
                {!mainContent.showIntroVideoBoolean &&
                mainContent.videoURL !== "" ? (
                  <div className="video-box">
                    <iframe
                      title="video"
                      width="100%"
                      height="100%"
                      src={mainContent.videoURL}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div className="image-intro">
                    <img
                      className="iframe videoiframe cr-img remove_animated fadeIn"
                      src={mainContent.introImgURL}
                      alt={
                        mainContent.altIntroImgURL !== undefined
                          ? mainContent.altIntroImgURL
                          : ""
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </BrowserView>

        <MobileView>
          <div className="carousel-images">
            {carouselMobile && (
              <Carousel
                showStatus={false}
                autoPlay={true}
                showIndicators={false}
                swipeable={true}
                emulateTouch={true}
                thumbWidth={100}
                infiniteLoop
                showThumbs={false}
              >
                {carouselMobile.length > 0 &&
                  carouselMobile.map((item, index) => {
                    return (
                      <div key={index} className="box-carousel">
                        <a href={item.linkBanner}>
                          <img
                            className="image-carousel"
                            src={item.imageURL}
                            alt={item.imageAlt || ""}
                          />
                        </a>
                      </div>
                    );
                  })}
              </Carousel>
            )}
          </div>
          <div className="col-12 texto-banner">
            <span className="font-48 col-7 f-weight-500 cl-white">
              <span className="cl-yellow f-weight-500">
                <h1>{mainContent.titleIntro}</h1>
              </span>
            </span>
            <p className="font-24 col-7 mb-5 cl-white">
              {mainContent.textIntro}
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=551140035542"
              rel="noopener noreferrer"
              target="_blank"
              className="bt border-white cl-white font-16"
            >
              ou fale por WhatsApp{" "}
              <img
                className="icon"
                width="24"
                height="24"
                alt=""
                src={ArrowWhite}
              />
            </a>
          </div>
          <div className="fundomobi">
            <div className="row no-gutters">
              <img
                src={mainContent.introImgMobileURL}
                alt={mainContent.altIntroImgMobile}
              />
            </div>
          </div>
          {!mainContent.showIntroVideoBoolean &&
            mainContent.introVideoURL !== "" && (
              <div className="video-box">
                <iframe
                  title="video"
                  width="100%"
                  height="100%"
                  src={mainContent.videoURL}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
        </MobileView>
      </div>

      <div id="produtos">
        <LazyLoad>
          <div className="content">
            <div className="row">
              <div className="col-12 title" align="center">
                <h2 className="cl-black font-48">
                  {mainContent.titleBenefitSection}
                </h2>
                <p className="col-lg-5 bg-yellow cl-black bt font-22">
                  {
                    <PrismicRichText
                      field={mainContent.subtitleBenefitSection}
                      components={{
                        paragraph: ({ children, node }) => (
                          <span key={node.text} className="paragraph">
                            {children}
                          </span>
                        ),
                      }}
                    />
                  }
                </p>
              </div>

              <div className="col-12 row no-gutters">
                {plansFormatted &&
                  plansFormatted.map((plan) => {
                    return (
                      <LazyLoad
                        key={plan.id}
                        className="plan-card col-lg-4 col-md-12"
                        animateIn="fadeIn"
                      >
                        <a
                          href={plan.btnLink || "#"}
                          className="col-lg-4 col-md-12 box"
                        >
                          <img
                            className="img-card"
                            alt={plan.imageAlt || ""}
                            src={plan.imageURL}
                          ></img>
                          <p className="pdspc cl-gray font-28">
                            <PrismicRichText
                              field={plan.title}
                              components={{
                                paragraph: ({ children, node }) => (
                                  <span key={node.text} className="plan-title">
                                    {children}
                                  </span>
                                ),
                              }}
                            />
                          </p>
                          {plan.short_text && (
                            <span
                              className="col-9 cl-gray text-center font-18 txt-pc font-p-mobal1"
                              align="center"
                            >
                              {plan.short_text}
                            </span>
                          )}
                          <span className="col-10 text-center cl-gray font-28 txt-pc font-p-mobal1">
                            {plan.subtitle}
                          </span>
                          <span className="cl-light-blue font-16 font-p-mobal1 tb-fipe">
                            {plan.observation}
                          </span>
                          <p className="cl-gray font-16 font-p-mobal1">
                            {plan.price && <span>Planos a partir de</span>}
                          </p>
                          <span className="cl-blue font-32 f-weight-700 font-p-mobal">
                            {plan.price ? (
                              <>
                                <span>{plan.price}</span>
                                <span className="cl-gray font-16 f-weight-400">
                                  /mensais
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <div className="button-card bg-light-blue cl-white bt font-18">
                            {plan.btnText}{" "}
                            <img
                              className="icon"
                              width="24"
                              height="24"
                              alt=""
                              src={ArrowYellow}
                            />
                          </div>
                        </a>
                      </LazyLoad>
                    );
                  })}
              </div>
            </div>
          </div>
        </LazyLoad>
      </div>

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <Link
                to="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </Link>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img width="55" height="47" alt="" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img width="520" height="293" alt="" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <Link
                to="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </Link>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div className="d-none" id="line-garante">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img width="55" height="47" alt="" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
