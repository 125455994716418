import React, { useState, useEffect } from "react";

import "./Form.scss";
import { FiLoader } from "react-icons/fi";
import swal from "sweetalert";

// import { api, checkout } from "../../Api/app";
import { newCheckout } from "../../Api/pwr";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";
import Logo from "../../images/forms/logo-residencia.svg";

import InputMask from "react-input-mask";
import { useSinglePrismicDocument } from "@prismicio/react";
import { FormContent } from "../../Components/FormContent";

export default function Index(props) {
  const [menuPlano, setmenuPlano] = useState("");

  const [stepLoad, setstepLoad] = useState(false);
  const [camposFormulario, setCamposFormularios] = useState({
    nome: "",
    telefone: "",
    email: "",
    mensagem: "",
    plano: "",
  });

  const superResidenciaPageGraphQuery = `{
        pagina_super_residencia{
            form_title
            form_subtitle
            form_img
            form_img_mobile
            planos{
              plano{
                ...on planos_residencia{
                  price
                } 
              }
            } 
          }
    }`.replace(/^ +| +$/gm, "");
  const [superResidenciaPageContent] = useSinglePrismicDocument(
    "pagina_super_residencia",
    {
      graphQuery: superResidenciaPageGraphQuery,
    }
  );

  const propsFormContent = {
    imgURL: superResidenciaPageContent?.data.form_img?.url
      ? superResidenciaPageContent?.data.form_img.url
      : "#",
    imgAlt: superResidenciaPageContent?.data.form_img?.alt
      ? superResidenciaPageContent?.data.form_img.alt
      : "",
    imgMobileURL: superResidenciaPageContent?.data.form_img_mobile?.url
      ? superResidenciaPageContent?.data.form_img_mobile.url
      : "#",
    imgMobileAlt: superResidenciaPageContent?.data.form_img_mobile?.alt
      ? superResidenciaPageContent?.data.form_img_mobile.alt
      : "",
    title: superResidenciaPageContent?.data.form_title[0]?.text
      ? superResidenciaPageContent.data.form_title[0].text
      : "",
    subtitle: superResidenciaPageContent?.data.form_subtitle[0]?.text
      ? superResidenciaPageContent.data.form_subtitle[0].text
      : "",
  };

  const planPrices = superResidenciaPageContent?.data?.planos?.map((plan) => {
    return plan.plano?.data?.price ? parseFloat(plan.plano?.data.price) : 0;
  });

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    let plano_url = query.get("plano");
    if (plano_url) {
      let opcoes = {
        1: "PLANO_RESIDENCIALBASICO",
        2: "PLANO_RESIDENCIALPLUS",
        3: "PLANO_RESIDENCIAL_PREMIUM",
      };
      if (opcoes[plano_url]) setmenuPlano(opcoes[plano_url]);
    }
  }, [props.location.search]);

  async function insertPasso1(event) {
    if (event) event.preventDefault();

    if ("Plano" === menuPlano || "" === menuPlano) {
      swal({
        title: " ",
        text: "Selecione seu plano",
        showCancelButton: false,
        showConfirmButton: false,
      });
      return;
    }

    let slsmnId = null;
    setstepLoad(true);

    let response = await newCheckout.post("quotation?plano=" + menuPlano, {
      h: "sp beneficios507",
      slsmnId: slsmnId,
      slsmnNwId: "NrP2PKql",
      name: camposFormulario.nome,
      email: camposFormulario.email,
      phone: camposFormulario.telefone,
    });

    window.location.href = "/checkout?id=" + response.data.back;
  }

  /* async function handleSend(event) {
    event.preventDefault();

    var data = camposFormulario;

    setstepLoad(true);

    var response = await api.post("/form-casa", data);

    if (response.data.error == true) {
      alert(response.data.message);
    } else {
      //swal("Enviado com sucesso!");
      var response = await checkout.post("/create", data);
      var next = response.data.url;
      window.location.href = next;
    }

    setTimeout(function () {
      setstepLoad(false);
    });
  } */

  function changeInputMult(e) {
    var meusdados = camposFormulario;
    meusdados[e.target.name] = e.target.value;
    setCamposFormularios({ ...meusdados });
  }

  return (
    <div id="credenciados">
      <div id="head"></div>
      <div id="formg">
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-12 left-form">
              <FormContent props={propsFormContent} />
            </div>
            <div className="col-lg-6 col-md-12">
              <form
                className="box-form row no-gutters form animated fadeIn"
                onSubmit={insertPasso1}
                method="post"
              >
                <div className="col-12 row no-gutters infos">
                  <img width="203" height="59" alt="" src={Logo} />
                  <p className="font-16">
                    A qualidade de vida que sua família merece não precisa
                    custar caro!
                    <br />
                    <b className="cl-light-blue">
                      Não fazemos análise de Perfil.
                    </b>
                  </p>
                </div>
                <div className="col-12 row no-gutters">
                  <div
                    className="col-12 row  form-gen no-gutters"
                    method="post"
                  >
                    <div className="col-12">
                      <select
                        name="plano"
                        required
                        value={menuPlano}
                        onChange={(e) => {
                          setmenuPlano(e.target.value);
                          changeInputMult(e);
                        }}
                      >
                        <option value="Plano">Plano</option>
                        <option value="PLANO_RESIDENCIALBASICO">
                          Super Residencial Essencial |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[0]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                        <option value="PLANO_RESIDENCIALPLUS">
                          Super Residencial + |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[1]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                        <option value="PLANO_RESIDENCIAL_PREMIUM">
                          Super Residencial Premium |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[2]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        required
                        value={camposFormulario.nome}
                        onChange={(e) => changeInputMult(e)}
                        name="nome"
                        placeholder="Nome completo"
                      />
                    </div>

                    <div className="col-12">
                      <InputMask
                        mask="(99) 99999-9999"
                        required
                        type="text"
                        value={camposFormulario.telefone}
                        onChange={(e) => changeInputMult(e)}
                        name="telefone"
                        placeholder="Telefone"
                      />
                    </div>

                    <div className="col-12" align="right">
                      <input
                        type="text"
                        name="email"
                        required
                        value={camposFormulario.email}
                        onChange={(e) => changeInputMult(e)}
                        placeholder="E-mail"
                      />
                    </div>

                    <div className="ipt-check col-12">
                      <button type="submit" disabled={stepLoad}>
                        {stepLoad === true ? <FiLoader /> : "Contratar!"}{" "}
                        <img width="24" height="24" alt="" src={ArrowYellow} />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
