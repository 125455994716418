import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import Modal from "../../Components/Modal/index";

import { Carousel } from "react-responsive-carousel";

import BgBanner from "../../images/planos/banner-background.png";

import BulletMais from "../../images/planos/super-vida/icon-plus3.svg";
import BulletMais1 from "../../images/planos/super-vida/icon-plus1.svg";
import BulletMais2 from "../../images/planos/super-vida/icon-plus2.svg";

import Points from "../../images/planos/super-vida/points.svg";
import PointsBene from "../../images/planos/super-vida/points-bene.svg";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Heart from "../../images/icon/coracao-garante.svg";

import ModalImage from "../../images/modal/image-modal.png";
import { BrowserView, MobileView } from "react-device-detect";

import { NewPlans } from "../../Components/NewPlans";

import { useSinglePrismicDocument, PrismicRichText } from "@prismicio/react";

import "./Planos.scss";
import { BannerFooter } from "../../Components/BannerFooter";

export default function Index() {
  const [modalFtActive, setModalFtActive] = useState(false);

  const superHealthPageGraphQuery = `{
        pagina_super_saude{
            plan_logo
            img_aside_logo
            
            ad_newplans_link{
                ...on anuncio_de_novos_planos{
                new_plans
              }
            }
            footer_img
            image_footer_mobile
            footer_text
            btn_text_footer
            plans{
              plan_link{
              ...on plano_saude{
                  plan_img
                  items
                  text
                  price
                  btn_text
                  btn_link
              }
            }
          } 
          title_main
          texto_1
          texto_2
          sub_text
          main_image
          icon_title
          icons
          image_left_side_dk
          plan_logo_main
          btn_text
          btn_link
        }
    }`.replace(/^ +| +$/gm, "");
  const [superHealthPageContent] = useSinglePrismicDocument(
    "pagina_super_saude",
    {
      graphQuery: superHealthPageGraphQuery,
    }
  );

  const propsBannerComponent = {
    imageFooter: superHealthPageContent?.data.footer_img?.url
      ? superHealthPageContent?.data.footer_img.url
      : "",
    imageFooterAlt: superHealthPageContent?.data.footer_img?.alt
      ? superHealthPageContent?.data.footer_img.alt
      : "",
    imageFooterMobile: superHealthPageContent?.data.image_footer_mobile?.url
      ? superHealthPageContent?.data.image_footer_mobile.url
      : "",
    imageFooterMobileAlt: superHealthPageContent?.data.image_footer_mobile?.alt
      ? superHealthPageContent?.data.image_footer_mobile.alt
      : "",
    textFooter: superHealthPageContent?.data.footer_text[0]?.text
      ? superHealthPageContent?.data.footer_text[0].text
      : "",
    btnText: superHealthPageContent?.data.btn_text_footer[0]?.text
      ? superHealthPageContent?.data.btn_text_footer[0].text
      : "",
    modalControl: setModalFtActive,
  };
  const mainContent = {
    logo: superHealthPageContent?.data.plan_logo?.url
      ? superHealthPageContent?.data.plan_logo.url
      : "",
    imgAsideLogo: superHealthPageContent?.data.img_aside_logo?.url
      ? superHealthPageContent?.data.img_aside_logo.url
      : "",
  };

  const plansFormatted = superHealthPageContent?.data.plans?.map((plan) => {
    return {
      id: plan.plan_link.id,
      imageURL: plan.plan_link.data?.plan_img?.url
        ? plan.plan_link.data?.plan_img.url
        : "",
      items: plan.plan_link.data?.items.map((x) => x.item[0].text),
      text: plan.plan_link.data?.text ? plan.plan_link.data.text[0].text : "",
      price: plan.plan_link.data?.price
        ? Number(
            plan.plan_link.data.price[0].text.replace(",", ".")
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        : "",
      btnText: plan.plan_link.data?.btn_text
        ? plan.plan_link.data.btn_text[0].text
        : "",
      btnLink: plan.plan_link.data?.btn_link
        ? plan.plan_link.data.btn_link[0].text
        : "",
    };
  });

  const centerSection = {
    superTitle: superHealthPageContent?.data?.title_main
      ? superHealthPageContent.data.title_main[0].text
      : "",
    firstText: superHealthPageContent?.data?.texto_1
      ? superHealthPageContent.data.texto_1[0].text
      : "",
    secondText: superHealthPageContent?.data?.texto_2
      ? superHealthPageContent.data.texto_2[0].text
      : "",
    subText: superHealthPageContent?.data?.sub_text
      ? superHealthPageContent.data.sub_text
      : "",
    imgAside: superHealthPageContent?.data?.main_image?.url
      ? superHealthPageContent.data?.main_image.url
      : "",
  };

  const iconsSection = {
    title: superHealthPageContent?.data?.icon_title
      ? superHealthPageContent.data.icon_title
      : "",
    icons: superHealthPageContent?.data.icons.map((x) => x.icon.url),
  };

  const centerBanner = {
    leftImg: superHealthPageContent?.data.image_left_side_dk?.url
      ? superHealthPageContent?.data.image_left_side_dk.url
      : "",
    logoImg: superHealthPageContent?.data.plan_logo_main?.url
      ? superHealthPageContent?.data.plan_logo_main.url
      : "",
    btnText: superHealthPageContent?.data.btn_text
      ? superHealthPageContent.data.btn_text
      : "",
    btnLink: superHealthPageContent?.data.btn_link[0]?.text
      ? superHealthPageContent.data.btn_link[0].text
      : "",
  };

  const newPlans = superHealthPageContent?.data.ad_newplans_link.data?.new_plans
    ? superHealthPageContent?.data.ad_newplans_link.data.new_plans
    : undefined;

  return (
    <div id="super-vida">
      <div id="banner">
        <img alt="" src={BgBanner} className="animated fadeInDown" />
        <div className="content">
          <div className="d-flex conteudos">
            <div className="col-6">
              <img
                alt=""
                className="img animated fadeInLeft"
                src={mainContent.logo}
              />
            </div>
            <BrowserView>
              <div className="col-6">
                <img
                  alt=""
                  className="img dog animated fadeInLeft"
                  src={mainContent.imgAsideLogo}
                />
              </div>
            </BrowserView>
          </div>
        </div>
      </div>

      <div id="planos">
        <div className="content">
          <div className="row">
            {plansFormatted &&
              plansFormatted.map((plan, index) => {
                return (
                  <div key={plan.id} className="col-lg-4 col-md-12">
                    <ScrollAnimation animateIn="fadeIn" delay={100} animateOnce>
                      <div className="box bx1">
                        <div
                          className={
                            index === 0
                              ? "topo bg-p-blue1"
                              : index === 1
                              ? "topo bg-p-blue2"
                              : "topo bg-p-blue3"
                          }
                        >
                          <img alt="" src={plan.imageURL} />
                        </div>
                        <div className="list">
                          {plan.items?.length > 0 &&
                            React.Children.toArray(
                              plan.items.map((item) => {
                                return (
                                  <span className="cl-gray">
                                    <img
                                      alt=""
                                      src={
                                        index === 0
                                          ? BulletMais1
                                          : index === 1
                                          ? BulletMais2
                                          : BulletMais
                                      }
                                    />
                                    {item}
                                  </span>
                                );
                              })
                            )}
                        </div>
                        <div className="footer">
                          <p className="font-14 cl-gray f-wheight-700">
                            {plan.text}
                          </p>
                          <span className="cl-blue font-32 f-wheight-700 valor">
                            {plan.price}
                            <span className=" font-16 cl-gray f-wheight-500">
                              /mês
                            </span>
                          </span>
                          <a
                            href={plan.btnLink}
                            className={
                              index === 0
                                ? "bt f-wheight-600 bg-p-blue1 cl-white font-18"
                                : index === 1
                                ? "bt f-wheight-600 bg-p-blue2 cl-white font-18"
                                : "bt f-wheight-600 bg-p-blue3 cl-white font-18"
                            }
                          >
                            {plan.btnText}{" "}
                            <img
                              alt=""
                              width="24"
                              height="24"
                              src={ArrowYellow}
                            />
                          </a>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                );
              })}
            <BrowserView>
              <img alt="" className="points" src={Points} />
            </BrowserView>
          </div>
        </div>
      </div>

      <div id="qualidade">
        <div className="content">
          <div className="row">
            <div>
              <BrowserView>
                <p className="col-7 mr-auto ml-auto cl-light-blue f-weight-700 font-32 titulo text-center">
                  {centerSection.superTitle}
                </p>
              </BrowserView>
              <MobileView>
                <p className="col-12 mr-auto ml-auto cl-light-blue f-weight-700 font-32 titulo text-center">
                  {centerSection.superTitle}
                </p>
              </MobileView>
            </div>

            <BrowserView>
              <div className="col-12 row cts">
                <div className="col-lg-6 col-md-12 texts">
                  <div className="box">
                    <span className="cl-white font-18 f-weight-400">
                      E muito mais:
                    </span>
                    <p className="cl-white font-24 f-weight-700">
                      {centerSection.firstText} <br />
                      <span className="cl-yellow">
                        {centerSection.secondText}
                      </span>
                    </p>
                  </div>
                  <PrismicRichText
                    field={centerSection.subText}
                    components={{
                      paragraph: ({ children, node }) => (
                        <p key={node.text} className="cl-gray font-18">
                          {children}
                        </p>
                      ),
                    }}
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <img alt="" src={centerSection.imgAside} />
                </div>
              </div>
            </BrowserView>

            <MobileView>
              <div className="col-12 row cts">
                <div className="col-lg-6 col-md-12">
                  <img
                    alt=""
                    className="mobile-image"
                    src={centerSection.imgAside}
                  />
                </div>

                <div className="col-lg-6 col-md-12 texts">
                  <div className="box">
                    <span className="cl-white font-18 f-weight-400">
                      E muito mais:
                    </span>
                    <p className="cl-white font-24 f-weight-700">
                      {centerSection.firstText} <br />
                      <span className="cl-yellow">
                        {centerSection.secondText}
                      </span>
                    </p>
                  </div>
                  <PrismicRichText
                    field={centerSection.subText}
                    components={{
                      paragraph: ({ children, node }) => (
                        <p key={node.text} className="cl-gray font-18">
                          {children}
                        </p>
                      ),
                    }}
                  />
                </div>
              </div>
            </MobileView>
          </div>
        </div>
      </div>

      <div id="vantavida">
        <div className="content">
          <div className="row">
            <div className="col-12" align="center">
              <PrismicRichText
                field={iconsSection.title}
                components={{
                  paragraph: ({ children, node }) => (
                    <p key={node.text} className="cl-black font-32 title">
                      {children}
                    </p>
                  ),
                }}
              />
            </div>
          </div>

          <BrowserView>
            <div className="row line">
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index < 5) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
            <div className="row line2">
              <img alt="" className="obj1" src={PointsBene} />
              <img alt="" className="obj2" src={PointsBene} />
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon, index) => {
                    if (index > 4) {
                      return (
                        <div className="image">
                          <img alt="" src={icon} />
                        </div>
                      );
                    }
                    return "";
                  })
                )}
            </div>
          </BrowserView>
        </div>
        <MobileView>
          <div className="">
            <Carousel
              showStatus={false}
              showIndicators={false}
              autoPlay={true}
              swipeable={true}
              emulateTouch={true}
              stopOnHover={true}
              infiniteLoop
              centerMode
              centerSlidePercentage={50}
              showThumbs={false}
            >
              {iconsSection.icons &&
                React.Children.toArray(
                  iconsSection.icons.map((icon) => {
                    return (
                      <div className="col-12 image">
                        <img alt="" src={icon} />
                      </div>
                    );
                  })
                )}
            </Carousel>
          </div>
        </MobileView>
      </div>

      <div id="line-spa">
        <div className="content">
          <div className="row">
            <div className="col-lg-8">
              <img alt="" src={centerBanner.leftImg} className="image-super" />
            </div>
            <div className="col-lg-4">
              <div className="cts">
                <img
                  className="logo-banner"
                  alt=""
                  src={centerBanner.logoImg}
                />
                <a
                  href={centerBanner.btnLink}
                  className=" bt border-blue cl-blue font-16"
                >
                  <PrismicRichText
                    field={centerBanner.btnText}
                    components={{
                      paragraph: ({ children, node }) => (
                        <p key={node.text} className="cl-blue font-16">
                          {children}
                        </p>
                      ),
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {newPlans !== undefined && <NewPlans arrayNewPlans={newPlans} />}

      {propsBannerComponent && <BannerFooter props={propsBannerComponent} />}

      <Modal modalActive={modalFtActive}>
        <div id="modal-ini" className="bg-blue">
          <button
            onClick={(e) => setModalFtActive(false)}
            className="fechar"
          ></button>
          <img alt="" width="520" height="293" src={ModalImage} />
          <BrowserView>
            <div className="buttons">
              <Link
                to="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </Link>
              <Link
                to="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </Link>
              <Link
                to="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </Link>
              <Link
                to="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </Link>
              <Link
                to="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </Link>
            </div>
          </BrowserView>
          <MobileView>
            <div className="buttons">
              <a
                href="/form-auto"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Auto
              </a>
              <a
                href="/form-saude"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Saúde
              </a>
              <a
                href="/form-casa"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Residência
              </a>
              <a
                href="/form-pet"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Pet
              </a>
              <a
                href="/form-dental"
                onClick={(e) => setModalFtActive(false)}
                className="font-16 cl-white border-white bt"
              >
                Super Odonto
              </a>
            </div>
          </MobileView>
        </div>

        <div id="line-garante" className="d-none">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="box-2">
                  <img alt="" width="55" height="47" src={Heart} />
                  <span className="cl-blue font-28 f-weight-700">
                    Entenda como a Super
                    <br /> garante seus benefícios
                  </span>
                  <Link to="/super-garante" className="cl-blue border-blue bt">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
