import React, { useState } from "react";

import "@brainhubeu/react-carousel/lib/style.css";

import "./Form.scss";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import { FiLoader } from "react-icons/fi";
import swal from "sweetalert";

import { api } from "../../Api/app";
import { pwrCheckout } from "../../Api/pwr";

import Logo from "../../images/forms/logo-odonto.svg";

import InputMask from "react-input-mask";
import { useSinglePrismicDocument } from "@prismicio/react";
import { FormContent } from "../../Components/FormContent";

export default function Index() {
  const menuPlano = false;

  const [stepLoad, setstepLoad] = useState(false);
  const [camposFormulario, setCamposFormularios] = useState({
    nome: "",
    telefone: "",
    email: "",
    mensagem: "",
  });
  const superOdontoPageGraphQuery = `{
        pagina_super_odonto{
            form_title
            form_subtitle
            form_img
            form_img_mobile
          
        }
    }`.replace(/^ +| +$/gm, "");
  const [superOdontoPageContent] = useSinglePrismicDocument(
    "pagina_super_odonto",
    {
      graphQuery: superOdontoPageGraphQuery,
    }
  );

  const propsFormContent = {
    imgURL: superOdontoPageContent?.data.form_img?.url
      ? superOdontoPageContent?.data.form_img.url
      : "#",
    imgAlt: superOdontoPageContent?.data.form_img?.alt
      ? superOdontoPageContent?.data.form_img.alt
      : "",
    imgMobileURL: superOdontoPageContent?.data.form_img_mobile?.url
      ? superOdontoPageContent?.data.form_img_mobile.url
      : "#",
    imgMobileAlt: superOdontoPageContent?.data.form_img_mobile?.alt
      ? superOdontoPageContent?.data.form_img_mobile.alt
      : "",
    title: superOdontoPageContent?.data.form_title[0]?.text
      ? superOdontoPageContent.data.form_title[0].text
      : "",
    subtitle: superOdontoPageContent?.data.form_subtitle[0]?.text
      ? superOdontoPageContent.data.form_subtitle[0].text
      : "",
  };

  async function insertPasso1(event) {
    if (event) event.preventDefault();

    let slsmnId = null;

    if ("Plano" === menuPlano.value) {
      swal({
        title: " ",
        text: "Selecione seu plano",
        showCancelButton: false,
        showConfirmButton: false,
      });
      return;
    }

    swal({
      title: " ",
      text: "Enviado com sucesso, você será direcionado ao checkout para concluir sua proteção!",
      showCancelButton: false,
      showConfirmButton: false,
    });

    let response = await pwrCheckout.post("/qttnStep1?plano=PLANO_ODONTO", {
      h: "sp beneficios507",
      slsmnId: slsmnId,
      slsmnNwId: "NrP2PKql",
      name: camposFormulario.nome,
      email: camposFormulario.email,
      phone: camposFormulario.telefone,
    });

    window.location.href =
      "https://crm.superbeneficios.com/checkout?h=" + response.data.back;
  }

  async function handleSend(event) {
    event.preventDefault();

    var data = camposFormulario;

    setstepLoad(true);

    var response = await api.post("/form-odonto", data);

    if (response.data.error === true) {
      alert(response.data.message);
    } else {
      swal("Enviado com sucesso!");
    }

    setTimeout(function () {
      setstepLoad(false);
    });
  }

  function changeInputMult(e) {
    var meusdados = camposFormulario;
    meusdados[e.target.name] = e.target.value;
    setCamposFormularios({ ...meusdados });
  }

  return (
    <div id="credenciados">
      <div id="head"></div>
      <div id="formg">
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-12 left-form">
              <FormContent props={propsFormContent} />
            </div>
            <div className="col-lg-6 col-md-12">
              <form
                className="box-form row no-gutters form animated"
                onSubmit={insertPasso1}
                method="post"
              >
                <div className="col-12 row no-gutters infos">
                  <img width="203" height="59" alt="" src={Logo} />
                  <p font-16>
                    A qualidade de vida que sua família merece não precisa
                    custar caro!
                    <br />
                    <b className="cl-light-blue">
                      Não fazemos análise de Perfil.
                    </b>
                  </p>
                </div>
                <div className="col-12 row no-gutters">
                  <form
                    className="col-12  row form-gen  no-gutters"
                    method="post"
                    onSubmit={handleSend}
                  >
                    <div className="col-12">
                      <input
                        type="text"
                        required
                        value={camposFormulario.nome}
                        onChange={(e) => changeInputMult(e)}
                        name="nome"
                        placeholder="Nome completo"
                      />
                    </div>

                    <div className="col-12">
                      <InputMask
                        mask="(99) 99999-9999"
                        required
                        type="text"
                        value={camposFormulario.telefone}
                        onChange={(e) => changeInputMult(e)}
                        name="telefone"
                        placeholder="Telefone"
                      />
                    </div>

                    <div className="col-12" align="right">
                      <input
                        type="text"
                        required
                        name="email"
                        value={camposFormulario.email}
                        onChange={(e) => changeInputMult(e)}
                        placeholder="E-mail"
                      />
                    </div>

                    <div className="ipt-check col-12">
                      <button type="submit" onClick={() => insertPasso1()}>
                        {stepLoad === true ? (
                          <FiLoader />
                        ) : (
                          "Quero mais informações"
                        )}{" "}
                        <img width="24" height="24" alt="" src={ArrowYellow} />{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
