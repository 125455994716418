export const partners = [
  {
    Nome: "HERMELINDO MARTINS DE AGUIAR-ME",
    "Nome Fantasia": "OFICINA MARTINS",
    Logradouro: "RUA JOAQUIM ELOI DA CONCEICAO",
    Número: 75,
    Bairro: "CACHOEIRA DO CAMPO",
    Complemento: "",
    Cidade: "OURO PRETO",
    UF: "MG",
    Telefone: "(31)3553-1559",
    Site: "sinistro@superprotecao.club",
  },
  {
    Nome: "VERMELHO VEICULOS LTDA",
    "Nome Fantasia": "VERMELHO VEICULOS",
    Logradouro: "AVENIDA MANSUR FRAYHA",
    Número: 637,
    Bairro: "JARDIM ELIZABETE",
    Complemento: "",
    Cidade: "POÇOS DE CALDAS",
    UF: "MG",
    Telefone: "(35)3714-1077",
    Site: "",
  },
  {
    Nome: "MOTOR FORTE BH LTDA",
    "Nome Fantasia": "MOTOR FORTE",
    Logradouro: "RUA VILA RICA",
    Número: 928,
    Bairro: "PADRE EUSTÁQUIO",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)99710-2619",
    Site: "",
  },
  {
    Nome: "SHOWCAR K PINTURA LANTERNAGEM E LAJA JATO",
    "Nome Fantasia": "SHOWCAR",
    Logradouro: "AV. MARIO WERNEK",
    Número: 931,
    Bairro: "ESTORIL",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)99982-3848",
    Site: "",
  },
  {
    Nome: "WILSON CARLELE GONÇALVES",
    "Nome Fantasia": "SPEED CAR",
    Logradouro: "Rua São Carlos",
    Número: 85,
    Bairro: "Vila Esportiva",
    Complemento: "",
    Cidade: "Vespasiano",
    UF: "MG",
    Telefone: "(31)3645-1303",
    Site: "",
  },
  {
    Nome: "AUTO CENTER CAPRICHO LTDA",
    "Nome Fantasia": "CAPRICHO",
    Logradouro: "RUA MONTE ALVERNE",
    Número: 340,
    Bairro: "SANTA MÔNICA",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)3450-1751",
    Site: "",
  },
  {
    Nome: "EDIVALDO FERREIRA DA COSTA",
    "Nome Fantasia": "ALPHA",
    Logradouro: "RUA LUCINDA DE CARVALHO MARQUES",
    Número: 135,
    Bairro: "BARÃO GERALDO",
    Complemento: "",
    Cidade: "CAMPINAS",
    UF: "SP",
    Telefone: "(19)3043-2633",
    Site: "",
  },
  {
    Nome: "JOSE OLEGARIO FERREIRA DA SILVA JUNIO ME",
    "Nome Fantasia": "OFICINA VISUAL CAR",
    Logradouro: "RUA RIO SOLIMÕES",
    Número: 700,
    Bairro: "PARQUE RIACHO DAS PEDRAS",
    Complemento: "",
    Cidade: "CONTAGEM",
    UF: "MG",
    Telefone: "(31)2567-2799_",
    Site: "",
  },
  {
    Nome: "ARI JOSE DE GODOI",
    "Nome Fantasia": "FUNILARIA E PINTURA GODOI",
    Logradouro: "RUA SOFIA MITELMÃO",
    Número: 262,
    Bairro: "PARQUE SÃO BENTO",
    Complemento: "",
    Cidade: "SOROCABA",
    UF: "SP",
    Telefone: "(15)3313-2712",
    Site: "",
  },
  {
    Nome: "MAQUINE AUTO CENTER EIRELI",
    "Nome Fantasia": "MAQUINE",
    Logradouro: "RUA RIO PARANAGUÁ",
    Número: 1382,
    Bairro: "PARQUE RIACHO DAS PEDRAS",
    Complemento: "",
    Cidade: "CONTAGEM",
    UF: "MG",
    Telefone: "(31)2564-7389",
    Site: "",
  },
  {
    Nome: "NOVA CONQUISTA FUNILARIA E PINTURA LTDA",
    "Nome Fantasia": "NOVA CONQUISTA",
    Logradouro: "AVENIDA IPANEMA",
    Número: 2550,
    Bairro: "VILA NOVA SOROCABA",
    Complemento: "SOROCABA",
    Cidade: "SOROCABA",
    UF: "SP",
    Telefone: "(15)3357-2808",
    Site: "",
  },
  {
    Nome: "OCTAVIO RODRIGUES ALVES",
    "Nome Fantasia": "OM REPAROS",
    Logradouro: "RUA ENGENHEIRO AGRÔNOMO ROMANO COURY",
    Número: 696,
    Bairro: "JARDIM CAXAMBU",
    Complemento: "",
    Cidade: "PIRACICABA",
    UF: "SP",
    Telefone: "(19)97101-0531",
    Site: "",
  },
  {
    Nome: "CENTRO AUTOMOTIVO MARTINS",
    "Nome Fantasia": "CENTRO AUTOMOTIVO MARTINS",
    Logradouro: "RUA PADRE PEDRO PINTO",
    Número: 2030,
    Bairro: "VENDA NOVA",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)3452-4276",
    Site: "",
  },
  {
    Nome: "SENNA AUTOMOTIVO",
    "Nome Fantasia": "SENNA AUTOMOTIVO",
    Logradouro: "RUA MARIA DOS ANJOS RIBEIRO DE JESUS",
    Número: 736,
    Bairro: "SENHORA DAS GRAÇAS",
    Complemento: "",
    Cidade: "BETIM",
    UF: "MG",
    Telefone: "(31)3596-8009_",
    Site: "",
  },
  {
    Nome: "CLAUDIO MARCIO ATTILIO 13007829860",
    "Nome Fantasia": "MECANICA PACHECO",
    Logradouro: "RUA AURÉLIA",
    Número: 1655,
    Bairro: "VILA ROMANA",
    Complemento: "",
    Cidade: "SÃO PAULO",
    UF: "SP",
    Telefone: "(11)3862-8022_",
    Site: "",
  },
  {
    Nome: "AUTO ELETRICA COLINA LTDA (Somente eletrica)",
    "Nome Fantasia": "AUTO ELETRICA COLINA",
    Logradouro: "AVENIDA THEREZA ANA CECON BREDA",
    Número: 1640,
    Bairro: "JARDIM DAS COLINAS",
    Complemento: "",
    Cidade: "HORTOLÂNDIA",
    UF: "SP",
    Telefone: "(19)3865-2662",
    Site: "",
  },
  {
    Nome: "VELOSO E FILHOS LTDA",
    "Nome Fantasia": "OFICINA VELOSO",
    Logradouro: "AV. PRESIDENTE TANCREDO NEVES",
    Número: 2326,
    Bairro: "CASTELO",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)3476-7395",
    Site: "",
  },
  {
    Nome: "CAR SERVICE OFICINA BH COMERCIO DE PEÇAS E SERVIÇOS AUTOMOTIVOS EIRELLI-ME",
    "Nome Fantasia": "CAR SERVICE",
    Logradouro: "RUA MAJOR DELFINO DE PAULA",
    Número: 2608,
    Bairro: "SÃO FRANCISCO",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)2535-9500",
    Site: "",
  },
  {
    Nome: "PEREZ E BARBOSA PEÇAS E SERVIÇOS MECANICOS LTDA (somente Motos)",
    "Nome Fantasia": "ROSS MOTOS",
    Logradouro: "RUA PEDRO LESSA",
    Número: 195,
    Bairro: "JARDIM PROFESSOR FRANCISCO MOR",
    Complemento: "LOJA 02",
    Cidade: "FRANCISCO MORATO",
    UF: "SP",
    Telefone: "(11)4934-0576",
    Site: "",
  },
  {
    Nome: "PERSONAL CHECKUP SERVIÇOS AUTOMOTIVOS EIRELI",
    "Nome Fantasia": "PERSONAL CHECKUP",
    Logradouro: "RUA PAULO PIEDADE CAMPOS",
    Número: 2830,
    Bairro: "ESTORIL",
    Complemento: "",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)3041-2118",
    Site: "",
  },
  {
    Nome: "GILMAR CARVALHO DA SILVA",
    "Nome Fantasia": "OFICINA PRIMER",
    Logradouro: "AVENIDA SÃO MIGUEL",
    Número: 5471,
    Bairro: "PARQUE BOTURUSSU",
    Complemento: "",
    Cidade: "SÃO PAULO",
    UF: "SP",
    Telefone: "(11)9835-92993",
    Site: "",
  },
  {
    Nome: "ANTÔNIO ESPÍRITO SANTO CATHARINO ME",
    "Nome Fantasia": "OFICINA ESTRELA",
    Logradouro: "RUA PADRE SALÉSIO",
    Número: 20,
    Bairro: "JARDIM SÃO DOMINGOS",
    Complemento: "",
    Cidade: "CAMPO LIMPO PAULISTA",
    UF: "SP",
    Telefone: "(11)4039-2401_",
    Site: "",
  },
  {
    Nome: "THIAGO LUIS NOGUEIRA CACETTI",
    "Nome Fantasia": "OFICINA AUTO BRILHO",
    Logradouro: "AVENIDA JOÃO JORGE",
    Número: 9,
    Bairro: "VILA INDUSTRIAL",
    Complemento: "",
    Cidade: "CAMPINAS",
    UF: "SP",
    Telefone: "(19)9961-20088",
    Site: "",
  },
  {
    Nome: "VERTT CAR SERVICOS DE FUNILARIA E PINTURA EIRELI ME",
    "Nome Fantasia": "VERTT CAR",
    Logradouro: "RUA ARNALDO BARRETO",
    Número: 938,
    Bairro: "SãO BERNARDO",
    Complemento: "",
    Cidade: "CAMPINAS",
    UF: "SP",
    Telefone: "(19)3242-0472",
    Site: "",
  },
  {
    Nome: "MENICHELLI E RAYMUNDO MECANICA E FUNILARIA E PINTURA LTDA ME",
    "Nome Fantasia": "EDCAR",
    Logradouro: "RUA RIO ITAPIçU-MIRIM",
    Número: 529,
    Bairro: "JARDIM SãO LUíS (ZONA LESTE)",
    Complemento: "",
    Cidade: "SãO PAULO",
    UF: "SP",
    Telefone: "(11)2561-3735",
    Site: "",
  },
  {
    Nome: "GAUCHA CAR ME",
    "Nome Fantasia": "GAUCHA CAR",
    Logradouro: "RUA SãO FELIPE",
    Número: 100,
    Bairro: "PARQUE SãO JORGE",
    Complemento: "",
    Cidade: "SãO PAULO",
    UF: "SP",
    Telefone: "(11)2097-4655",
    Site: "",
  },
  {
    Nome: "ARI SILVA DE ALMEIDA",
    "Nome Fantasia": "FUNILARIA DO ARI",
    Logradouro: "ESTRADA DO ALVARENGA",
    Número: 1347,
    Bairro: "JARDIM DA PEDREIRA",
    Complemento: "ATé 1467/1468",
    Cidade: "SãO PAULO",
    UF: "SP",
    Telefone: "(11)4327-8072",
    Site: "",
  },
  {
    Nome: "SOCIAL PAULO SéRGIO MARQUES DA SILVA ME",
    "Nome Fantasia": "STOP CAR CAMPINAS",
    Logradouro: "RUA JOSé RAMON ABOIM GOMES",
    Número: 283,
    Bairro: "VILA NOGUEIRA",
    Complemento: "",
    Cidade: "CAMPINAS",
    UF: "SP",
    Telefone: "(19)9740-8044",
    Site: "",
  },
  {
    Nome: "COMERCIAL MOTOCHIC EIRELI (somente motos)",
    "Nome Fantasia": "MOTOCHIC",
    Logradouro: "AVENIDA ABíLIO MACHADO",
    Número: 1332,
    Bairro: "INCONFIDêNCIA",
    Complemento: "ATé 1468 - LADO PAR",
    Cidade: "BELO HORIZONTE",
    UF: "MG",
    Telefone: "(31)3568-3879",
    Site: "",
  },
  {
    Nome: "Leugim Centro Automotivo",
    "Nome Fantasia": "Leugim",
    Logradouro: "R. Italina Pereira Mota",
    Número: 600,
    Bairro: "Jardim Camburi",
    Complemento: "",
    Cidade: "Vitoria",
    UF: "ES",
    Telefone: "(27) 3337-7682",
    Site: "",
  },
  {
    Nome: "SAFETY CAR",
    "Nome Fantasia": "SAFETY CAR",
    Logradouro: "AVENIDA FREI HENRIQUE SOARES",
    Número: 433,
    Bairro: "Inconfidentes",
    Complemento: "",
    Cidade: "CONTAGEM",
    UF: "MG",
    Telefone: "(31) 3362-4042",
    Site: "",
  },
  {
    Nome: "LANTERNAGEM E PINT SOBRADINHO",
    "Nome Fantasia": "SOBRADINHO",
    Logradouro: "RUA PADRE VIEGAS",
    Número: 120,
    Bairro: "JARDIM INDUSTRIAL",
    Complemento: "",
    Cidade: "CONTAGEM",
    UF: "MG",
    Telefone: "(31) 3422-4764",
    Site: "",
  },
  {
    Nome: "MILENIUM AUTO ESTUFA",
    "Nome Fantasia": "MILENIUM",
    Logradouro: "RUA ALBERTO PACIULLI",
    Número: 414,
    Bairro: "SANTO ANTONIO",
    Complemento: "",
    Cidade: "Pouso Alegre",
    UF: "MG",
    Telefone: "(35) 3422-0543",
    Site: "",
  },
  {
    Nome: "ALFA CAR",
    "Nome Fantasia": "ALFA CAR",
    Logradouro: "RUA CABO BENEDITO ALVES",
    Número: 1177,
    Bairro: "COTIA",
    Complemento: "",
    Cidade: "Tres Corações",
    UF: "MG",
    Telefone: "(35) 3231-3048",
    Site: "",
  },
  {
    Nome: "OFICINA MARETTI",
    "Nome Fantasia": "MARETTI",
    Logradouro: "RUA ARGENTINA",
    Número: 700,
    Bairro: "JARDIM QUISISANA",
    Complemento: "",
    Cidade: "POÇOS DE CALDAS",
    UF: "MG",
    Telefone: "(35) 3712-5733",
    Site: "",
  },
  {
    Nome: "Sino Auto Eletrica",
    "Nome Fantasia": "Sino Auto Eletrica",
    Logradouro: "Av. Thereza Ana Cecon Breda",
    Número: 1640,
    Bairro: "Jd.Colinas",
    Complemento: "",
    Cidade: "Hortolandia",
    UF: "SP",
    Telefone: "(19) 3865-2662",
    Site: "",
  },
  {
    Nome: "Mecanica Fridão",
    "Nome Fantasia": "Mecanica Fridão",
    Logradouro: "Av. Thereza Ana Cecon Breda",
    Número: 30,
    Bairro: "Jd.Colinas",
    Complemento: "",
    Cidade: "Hortolandia",
    UF: "SP",
    Telefone: "(19) 3819-2350 / 99140-5404",
    Site: "",
  },
  {
    Nome: "Mecanica Do pastor Mereque",
    "Nome Fantasia": "Mecanica Do pastor Mereque",
    Logradouro: "Rua Tereza Ana Cecon Breda",
    Número: 1628,
    Bairro: "Jd.Colinas",
    Complemento: "",
    Cidade: "Hortolandia",
    UF: "SP",
    Telefone: "(19) 3897-4030 / 9.9862-1548",
    Site: "",
  },
  {
    Nome: "JR Oficina de Reparos",
    "Nome Fantasia": "JR Oficina de Reparos",
    Logradouro: "Rua Men Sá",
    Número: 433,
    Bairro: "Castelinho",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 3927-4360",
    Site: "",
  },
  {
    Nome: "Oficina Darci",
    "Nome Fantasia": "Oficina Darci",
    Logradouro: "Rua Dr. Otavio Martins de Toledo",
    Número: 355,
    Bairro: "Castelinho",
    Complemento: "",
    Cidade: "PIRACICABA",
    UF: "SP",
    Telefone: "(19) 3434-9318",
    Site: "",
  },
  {
    Nome: "Garae Ferrari Reparação Automotiva",
    "Nome Fantasia": "Garae Ferrar",
    Logradouro: "Rua Prof. Raul Duarte",
    Número: 21,
    Bairro: "Jaraguá",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 9.9637-2584",
    Site: "",
  },
  {
    Nome: "Funilaria e Pintura Zoio",
    "Nome Fantasia": "Funilaria e Pintura Zoio",
    Logradouro: "Rua Dona Idalina",
    Número: 51,
    Bairro: "Pauliceia",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 2532-2838 / 3433-6438 / 98319-1820",
    Site: "",
  },
  {
    Nome: "Pezzato e Pezzato Funilaria e Pintura",
    "Nome Fantasia": "Pezzato e Pezzato",
    Logradouro: "Rua Marajó",
    Número: 89,
    Bairro: "Verde",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 3433-9714 / 99728-7467",
    Site: "",
  },
  {
    Nome: "CN Car Funilaria",
    "Nome Fantasia": "CN Car Funilaria",
    Logradouro: "Rua Frei Vital de Primeiro",
    Número: "212/223",
    Bairro: "JD. California",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 2533-5650 / 3426-5101",
    Site: "",
  },
  {
    Nome: "Irmão Morales",
    "Nome Fantasia": "Irmão Morales",
    Logradouro: "Av. Piracicamirim",
    Número: 2727,
    Bairro: "Piracicamirim",
    Complemento: "",
    Cidade: "PIRACICABA",
    UF: "SP",
    Telefone: "(19) 3426-4399 / 99151-1837",
    Site: "",
  },
  {
    Nome: "FE Pinturas",
    "Nome Fantasia": "FE Pinturas",
    Logradouro: "RUA ENG. AGRÔNOMO ROMANO COURY",
    Número: 668,
    Bairro: "JARDIM CAXAMBU",
    Complemento: "",
    Cidade: "PIRACICABA",
    UF: "SP",
    Telefone: "(19) 99149-4615",
    Site: "",
  },
  {
    Nome: "Personal funilaria e Pintura",
    "Nome Fantasia": "Personal funilaria e Pintura",
    Logradouro: "Rua Visconde de Taunay",
    Número: 110,
    Bairro: "Higienópolis",
    Complemento: "",
    Cidade: "PIRACICABA",
    UF: "SP",
    Telefone: "(19) 982720504",
    Site: "",
  },
  {
    Nome: "Neguito Funilaria e Pintura",
    "Nome Fantasia": "Neguito Funilaria e Pintura",
    Logradouro: "Av. Recife",
    Número: 178,
    Bairro: "Bosque Agua Branca",
    Complemento: "",
    Cidade: "Piracicaba",
    UF: "SP",
    Telefone: "(19) 3426-7021",
    Site: "",
  },
  {
    Nome: "Dauto Lanternagem e Pintura- SIA Trecho ¾",
    "Nome Fantasia": "Dauto Lanternagem e Pintura",
    Logradouro: "Lotes 1331/35, Sia Trecho 3/4",
    Número: "",
    Bairro: "Sia Sul",
    Complemento: "",
    Cidade: "Brasília",
    UF: "DF",
    Telefone: "(61) 3234-2921",
    Site: "",
  },
  {
    Nome: "Estrela Azul Lanternagem e pintura",
    "Nome Fantasia": "Estrela Azul Lanternagem e pintura",
    Logradouro: "Quadra 15 Conjunto 6",
    Número: "",
    Bairro: "Cidade Automovel",
    Complemento: "",
    Cidade: "Brasília",
    UF: "DF",
    Telefone: "(61) 3245-4281",
    Site: "",
  },
  {
    Nome: "ÁGUIA SERVICE CENTRO AUTOMOTIVO",
    "Nome Fantasia": "ÁGUIA SERVICE",
    Logradouro: "R. Leur Lomanto",
    Número: 164,
    Bairro: "Recanto do Lago",
    Complemento: "",
    Cidade: "Teixeira de Freitas",
    UF: "BA",
    Telefone: "(73) 3013-3626/1619   what (73) 9.9979-8169",
    Site: "",
  },
  {
    Nome: "Centro Automotivo Pirâmide",
    "Nome Fantasia": "Pirâmide",
    Logradouro: "Av. Santa Isabel",
    Número: 166,
    Bairro: "Monte Castelo",
    Complemento: "",
    Cidade: "Teixeira de Freitas",
    UF: "BA",
    Telefone: "(73) 3292-5260",
    Site: "",
  },
  {
    Nome: "HC RENOVADORA DE VEICULOS",
    "Nome Fantasia": "HC",
    Logradouro: "R. Canárias",
    Número: 547,
    Bairro: "Centro",
    Complemento: "",
    Cidade: "Mucuri",
    UF: "BA",
    Telefone: "(73) 99846-4081",
    Site: "",
  },
  {
    Nome: "Carlinhos Manutenções Automotiva",
    "Nome Fantasia": "Carlinhos Manutenção",
    Logradouro: "R. Condessa Vimieiro",
    Número: 400,
    Bairro: "Alto do Cardoso",
    Complemento: "",
    Cidade: "Pindamonhangaba",
    UF: "SP",
    Telefone: "(12)3642-2688",
    Site: "",
  },
  {
    Nome: "Auto Palace Funilaria e Pintura",
    "Nome Fantasia": "Auto Palace",
    Logradouro: "Rua dos Sagrados Corações",
    Número: 1052,
    Bairro: "Jardim Santa Luzia",
    Complemento: "",
    Cidade: "Pindamonhangaba",
    UF: "SP",
    Telefone: "(12) 3522-1533 / 9.9757-3181",
    Site: "",
  },
];
