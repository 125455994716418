import React, { useState, useEffect } from "react";

import "./Form.scss";

import swal from "sweetalert";
import { FiLoader } from "react-icons/fi";

// import { api } from "../../Api/app";
import { pwrCheckout } from "../../Api/pwr";

import ArrowYellow from "../../images/icon/arrow-yellow.svg";

import Logo from "../../images/forms/logo-pet.svg";

import InputMask from "react-input-mask";
import { useSinglePrismicDocument } from "@prismicio/react";
import { FormContent } from "../../Components/FormContent";

export default function Index(props) {
  const stepLoad = false;
  const [menuPlano, setmenuPlano] = useState("");
  const [camposFormulario, setCamposFormularios] = useState({
    nome: "",
    telefone: "",
    email: "",
    mensagem: "",
    plano: "",
  });

  const superPetPageGraphQuery = `{
        pagina_super_pet{
            form_title
            form_subtitle
            form_img
            form_img_mobile
            planos{
              plan_link{
              ...on planos_pet{  
                  price
              }
            }
          } 
        }
    }`.replace(/^ +| +$/gm, "");
  const [superPetPageContent] = useSinglePrismicDocument("pagina_super_pet", {
    graphQuery: superPetPageGraphQuery,
  });

  const propsFormContent = {
    imgURL: superPetPageContent?.data.form_img?.url
      ? superPetPageContent?.data.form_img.url
      : "#",
    imgAlt: superPetPageContent?.data.form_img?.alt
      ? superPetPageContent?.data.form_img.alt
      : "",
    imgMobileURL: superPetPageContent?.data.form_img_mobile?.url
      ? superPetPageContent?.data.form_img_mobile.url
      : "#",
    imgMobileAlt: superPetPageContent?.data.form_img_mobile?.alt
      ? superPetPageContent?.data.form_img_mobile.alt
      : "",
    title: superPetPageContent?.data.form_title[0]?.text
      ? superPetPageContent.data.form_title[0].text
      : "",
    subtitle: superPetPageContent?.data.form_subtitle[0]?.text
      ? superPetPageContent.data.form_subtitle[0].text
      : "",
  };

  const planPrices = superPetPageContent?.data?.planos?.map((plan) => {
    return plan.plan_link?.data.price[0]?.text
      ? parseFloat(plan.plan_link?.data.price[0]?.text.replace(",", "."))
      : 0;
  });

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    let plano_url = query.get("plano");
    if (plano_url) {
      let opcoes = {
        1: "PLANO_PETBASICO",
        2: "PLANO_PETPLUS",
        3: "PLANO_PETPREMIUM",
      };
      if (opcoes[plano_url]) setmenuPlano(opcoes[plano_url]);
    }
  }, [props.location.search]);

  async function insertPasso1(event) {
    if (event) event.preventDefault();
    if ("Plano" === menuPlano) {
      swal({
        title: " ",
        text: "Selecione seu plano",
        showCancelButton: false,
        showConfirmButton: false,
      });
      return;
    }

    let slsmnId = null;
    swal({
      title: " ",
      text: "Enviado com sucesso, você será direcionado ao checkout para concluir sua proteção!",
      showCancelButton: false,
      showConfirmButton: false,
    });

    let response = await pwrCheckout.post("/qttnStep1?plano=" + menuPlano, {
      h: "sp beneficios507",
      slsmnId: slsmnId,
      slsmnNwId: "NrP2PKql",
      name: camposFormulario.nome,
      email: camposFormulario.email,
      phone: camposFormulario.telefone,
    });

    window.location.href =
      "https://crm.superbeneficios.com/checkout?h=" + response.data.back;
  }

  /* async function handleSend(event) {
    event.preventDefault();

    var data = camposFormulario;

    setstepLoad(true);

    var response = await api.post("/form-pet", data);

    if (response.data.error == true) {
      alert(response.data.message);
    } else {
      swal("Enviado com sucesso!");
    }

    setTimeout(function () {
      setstepLoad(false);
    });
  } */

  function changeInputMult(e) {
    var meusdados = camposFormulario;
    meusdados[e.target.name] = e.target.value;
    setCamposFormularios({ ...meusdados });
  }

  return (
    <div id="credenciados">
      <div id="head"></div>
      <div id="formg">
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-12 left-form">
              <FormContent props={propsFormContent} />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="box-form row no-gutters form animated fadeIn">
                <div className="col-12 row no-gutters infos">
                  <img width="203" height="59" alt="" src={Logo} />
                  <p className="font-16">
                    A qualidade de vida que sua família merece não precisa
                    custar caro!
                    <br />
                    <b className="cl-light-blue">
                      Não fazemos análise de Perfil.
                    </b>
                  </p>
                </div>
                <div className="col-12 row no-gutters">
                  <form
                    className="col-12  row form-gen  no-gutters"
                    onSubmit={insertPasso1}
                    method="post"
                  >
                    <div className="col-12">
                      <select
                        name="plano"
                        required
                        value={menuPlano}
                        onChange={(e) => {
                          setmenuPlano(e.target.value);
                          changeInputMult(e);
                        }}
                      >
                        <option>Plano</option>
                        <option value="PLANO_PETBASICO">
                          Super PET Essencial |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[0]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                        <option value="PLANO_PETPLUS">
                          Super PET + |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[1]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                        <option value="PLANO_PETPREMIUM">
                          Super PET Premium |{" "}
                          {planPrices !== undefined &&
                            Number(planPrices[2]).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          /mensais
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        required
                        value={camposFormulario.nome}
                        onChange={(e) => changeInputMult(e)}
                        name="nome"
                        placeholder="Nome completo"
                      />
                    </div>

                    <div className="col-12">
                      <InputMask
                        mask="(99) 99999-9999"
                        required
                        type="text"
                        value={camposFormulario.telefone}
                        onChange={(e) => changeInputMult(e)}
                        name="telefone"
                        placeholder="Telefone"
                      />
                    </div>

                    <div className="col-12" align="right">
                      <input
                        type="text"
                        required
                        name="email"
                        value={camposFormulario.email}
                        onChange={(e) => changeInputMult(e)}
                        placeholder="E-mail"
                      />
                    </div>

                    <div className="ipt-check col-12">
                      <button type="submit">
                        {stepLoad === true ? <FiLoader /> : "Contratar!"}{" "}
                        <img width="24" height="24" alt="" src={ArrowYellow} />{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
