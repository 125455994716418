import React from "react";

import "./Checkout.scss";

export default function Index(props) {
  return (
    <div id="desconto">
      <div id="head"></div>
      <iframe
        src="http://localhost:3002/checkout?cid={this.cotacao}"
        class="uk-height-viewport"
        frameborder="0"
      >
        {" "}
      </iframe>
    </div>
  );
}
